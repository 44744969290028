import React from "react";
import styles from "./HelpOverlay.module.css";
import close from "../../assets/images/close.svg";
import dots from "../../assets/images/help/dots.png";
import twod from "../../assets/images/help/twod.png";
import threed from "../../assets/images/help/threed.png";
import updown from "../../assets/images/help/updown.png";
import boundaries from "../../assets/images/help/boundaries.png";
import coords from "../../assets/images/help/coords.png";
import lock from "../../assets/images/help/lock.png";
import help from "../../assets/images/help/help.png";
import select from "../../assets/images/help/select.png";

function HelpOverlay() {
  const [active, setActive] = React.useState<boolean>(false);

  return (
    <>
      <span className={styles.trigger} onClick={() => setActive(true)}>
        ?
      </span>
      {active && (
        <div className={styles.wrapper}>
          <div className={styles.overlayHeader}>
            <h2>How to use this tool</h2>
            <div className={styles.close} onClick={() => setActive(false)}>
              Close
              <img src={close} alt="" />
            </div>
          </div>
          <div className={styles.overlayBody}>
            <div className={styles.row}>
              <div className={styles.image}>
                <img src={dots} alt="" />
              </div>
              <div className={styles.text}>
              Yellow and blue “dots” represent stimulation sites on flattened surface of lateral prefrontal cortex. Click to see their 3D location in the brain. 
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.image}>
                <img src={twod} alt="" />
              </div>
              <div className={styles.text}>
              Clcking a “dot” selects the stimulation site, and its connections will show on the right side of the screen, on flattened surface of ipsilateral cortex and in 3D views.

              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.image}>
                <img src={threed} alt="" />
              </div>
              <div className={styles.text}>On the right side of the screen, clicking on the flattened surface updates 3D views based on where you click, and vice versa.</div>
            </div>
            <div className={styles.row}>
              <div className={styles.image}>
                <img src={select} alt="" />
              </div>
              <div className={styles.text}>To select multiple stimulation sites, hold down “shift” and click on multiple “dots”. Mean connections of selected sites will show on the right.</div>
            </div>
            <div className={styles.row}>
              <div className={styles.image}>
                <img src={coords} alt="" />
              </div>
              <div className={styles.text}>
              To jump to certain 3D coordinates, enter numbers in the I/J/K or R/A/S fields under “Selected Coordinates”.
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.image}>
                <img src={updown} alt="" />
              </div>
              <div className={styles.text}>
              Alternatively, click the “up” and “down” arrows below 3D images to browse.
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.image}>
                <img src={boundaries} alt="" />
              </div>
              <div className={styles.text}>
              Switch brain templates, boundaries on flattened surface, overlays on flattened surface and in 3D views, and overlay opacity using the selectors on the bottom right side.
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.image}>
                <img src={lock} alt="" />
              </div>
              <div className={styles.text}>
              On the right side of screen, hold down “shift” and click on flattened surface or in 3D views for “retrograde” selection of stimulation sites. Stimulates sites not connecting to shift-clicked location will fade out on the left side of screen.
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.image}>
                <img src={help} alt="" />
              </div>
              <div className={styles.text}>
              If you have any questions or run into any issues, please feel free to reach out to us
                (<a href="mailto:ruix@mit.edu">ruix@mit.edu</a>).
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HelpOverlay;
