import React from "react";
import HelpOverlay from "../HelpOverlay/HelpOverlay";
import styles from "./PaneHeader.module.css";

type Props = {
  title: string;
  displayStyle: string;
};

function PaneHeader({ title, displayStyle }: Props) {
  return (
    <div className={`${styles.wrapper} ${styles[displayStyle]}`}>
      <h2>{title}</h2>
      <div>{displayStyle !== "blue" && <HelpOverlay></HelpOverlay>}</div>
    </div>
  );
}

export default PaneHeader;
