import React from "react";
import { ColorMapEntry } from "../types/ColorMap";
import { InjectionSite } from "../types/InjectionSite";
import { BoundaryType, OverlayColorLookupType } from "../types/OverlayParameters";
import { Point2D } from "../types/Point2D";
import { Point3D } from "../types/Point3D";
import { PointMap } from "../types/PointMap";
import { SliceType } from "../types/SliceType";

export class LookupTables {
  connectionsColorMap?: Map<number, ColorMapEntry>;
  parcellationColorMaps?: Map<OverlayColorLookupType, Map<number, ColorMapEntry>>;
  connections2Dto3DMap?: PointMap<Point2D, Point3D>;
  connections3Dto2DMap?: PointMap<Point3D, Point2D>;
  stimulationSites2Dto3DMap?: PointMap<Point2D, Point3D>;
  stimulationSites3Dto2DMap?: PointMap<Point3D, Point2D>;
  injectionSites: InjectionSite[] = [];
  activityPointsMap?: PointMap<Point2D, string[]>;
  pathImagesSite2d?: string;
  pathImagesSite3d?: string;
  pathImagesConnection2d?: string;
  pathImagesPregenerated?: string;
  availableConnectionsParcellations: OverlayColorLookupType[] = [];
  availableStimulationSitesBoundaries: BoundaryType[] = [];
  templateName?: string;
  ktoRTransform: (k: number) => number = () => 0;
  itoATransform: (i: number) => number = () => 0;
  jtoSTransform: (j: number) => number = () => 0;
  rtoKTransform: (k: number) => number = () => 0;
  atoITransform: (i: number) => number = () => 0;
  stoJTransform: (j: number) => number = () => 0;
  siteBorderLeftOffset: number = 0;
  siteBorderTopOffset: number = 0;
  connectionsBorderLeftOffset: number = 0;
  connectionsBorderTopOffset: number = 0;
  sliceCountMap: Map<SliceType, number> = new Map<SliceType, number>([]);
  imageDimensions3D: Map<SliceType, { width: number; height: number }> = new Map<SliceType, { width: number; height: number }>([]);
}

const LookupTablesContext: React.Context<LookupTables> = React.createContext(new LookupTables());

export default LookupTablesContext;