export const createImage = (url: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
    return image;
  });

export const fetchData = async (path: string): Promise<string[]> => {
  const response = await fetch(path);
  const responseText = await response.text();
  const lines = responseText.split(/\r|\n|\r/);
  // Skip the first line, which has the column names
  return lines.slice(1);
};

export const getElementPointFromClickPosition = (x: number, y: number, ref: HTMLElement) => {
  const boundingRect = ref.getBoundingClientRect();
  if (boundingRect) {
    const offsetTop = Math.round(boundingRect.top);
    const offsetLeft = Math.round(boundingRect.left);
    return {
      x: x - offsetLeft,
      y: y - offsetTop,
    };
  } else {
    return null;
  }
};

// Overlay one pixel value over another with a given opacity (as a value between 0 and 1)
export const blend = (background: number, overlay: number, opacity: number): number => {
  return Math.floor(background * (1 - opacity) + overlay * opacity);
};

// Safari is rendering the white background as either 253/254/255 in first byte
// But we need can't throw away "253/254" values, since they are used in the parcellation data
// So this is going to cause issues with Safari
export const isHighPixelValue = (value: number): boolean => {
  return value >= 255;
}

// Safari is rendering the black background as either 0/1 in first byte
// But we need can't throw away "1" values, since they are used in the parcellation data
// So this is going to cause issues with Safari
export const isLowPixelValue = (value: number): boolean => {
  return value < 1;
}