import React from "react";
import { InjectionSite } from "../../types/InjectionSite";
import { createImage, isLowPixelValue } from "../../utils/utils";
import Connection2DCanvas from "../Connection2DCanvas/Connection2DCanvas";

type Props = {
  injectionSite: InjectionSite;
  onSetActivePixels: (pixels: number[]) => void
};

function GenerateImage({ injectionSite, onSetActivePixels }: Props) {
  const [imageData, setImageData] = React.useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [activePixels, setActivePixels] = React.useState<number[]>();

  const getActivePixels = async (injectionSite: InjectionSite) => {
    const canvasSize = 500;
    const canvas = document.createElement("canvas");
    canvas.height = canvasSize;
    canvas.width = canvasSize;
    const context = canvas.getContext("2d");
    try {
      const image = await createImage(injectionSite.getImage());
      context?.drawImage(image, 0, 0, canvas.width, canvas.height);
    } catch (e) {
      // There are cases where we attempt to load an image without knowing if it actually
      // exists. For example, in cases where there is no connection activity, the connection
      // overlay activity files does not exist. In these cases, assume the image is empty
    }
    const imageData = context?.getImageData(0, 0, canvasSize, canvasSize).data;
    const activePixelIndexes = [];
    if (imageData) {
      for (var i = 0; i < imageData.length; i += 4) {
        if (!isLowPixelValue(imageData[i])) {
          activePixelIndexes.push(i);
        }
      }
    }
    return activePixelIndexes;
  };

    // Everything that happens after the images are loaded needs to go in the useEffect hook
    React.useEffect(() => {
      const doTheActivePixels = async () => {
        const pixels = await getActivePixels(injectionSite)
        setActivePixels(pixels);
        onSetActivePixels(pixels);
      };
      doTheActivePixels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [injectionSite]);
  

  return (
    <div>
      <h2>
        <a href={imageData} download={injectionSite.getShortName()}>
          {injectionSite.getShortName()}
        </a>
      </h2>
      <Connection2DCanvas injectionSites={[injectionSite]} onSetImageData={setImageData} />
    </div>
  );
}

export default GenerateImage;
