import React from "react";
import { InjectionSite } from "../../types/InjectionSite";
import { OverlayParameters } from "../../types/OverlayParameters";
import { SlicePosition } from "../../types/SlicePosition";
import BrainSlice from "../BrainSlice/BrainSlice";
import styles from "./BrainSlices.module.css";

type Props = {
  slicePosition: SlicePosition;
  retrogradeSlicePosition?: SlicePosition;
  onSetSlicePosition: (position: SlicePosition) => void;
  onSetRetrogradeSlicePosition?: (position: SlicePosition) => void;
  injectionSites?: InjectionSite[];
  overlayParameters?: OverlayParameters;
};

function BrainSlices({ slicePosition, retrogradeSlicePosition, onSetSlicePosition, onSetRetrogradeSlicePosition, injectionSites, overlayParameters}: Props) {
  return (
    <div className={styles.wrapper}>
      <BrainSlice
        type="sagittal"
        slicePosition={slicePosition}
        retrogradeSlicePosition={retrogradeSlicePosition}
        onSetSlicePosition={onSetSlicePosition}
        onSetRetrogradeSlicePosition={onSetRetrogradeSlicePosition}
        injectionSites={injectionSites}
        overlayParameters={overlayParameters}
      />
      <BrainSlice
        type="coronal"
        slicePosition={slicePosition}
        retrogradeSlicePosition={retrogradeSlicePosition}
        onSetSlicePosition={onSetSlicePosition}
        onSetRetrogradeSlicePosition={onSetRetrogradeSlicePosition}
        injectionSites={injectionSites}
        overlayParameters={overlayParameters}
      />
      <BrainSlice
        type="axial"
        slicePosition={slicePosition}
        retrogradeSlicePosition={retrogradeSlicePosition}
        onSetSlicePosition={onSetSlicePosition}
        onSetRetrogradeSlicePosition={onSetRetrogradeSlicePosition}
        injectionSites={injectionSites}
        overlayParameters={overlayParameters}
      />
    </div>
  );
}

export default BrainSlices;
