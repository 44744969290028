import React from "react";
import { CONNECTIONS_2D_HEIGHT, CONNECTIONS_2D_WIDTH } from "../../constants/constants";
import LookupTablesContext from "../../contexts/LookupTablesContext";
import { OverlayParameters, OverlayType } from "../../types/OverlayParameters";
import { Point2D } from "../../types/Point2D";
import { getConnectionParcellationImageName, getOverlayTypeFromBoundary, getParcellationColorMap } from "../../utils/lookup";
import RegionLabel from "../RegionLabel/RegionLabel";

type Props = {
  point: Point2D | null;
  overlayParameters: OverlayParameters;
};

function ConnectionRegionLabel({ point, overlayParameters }: Props) {
  const lookupTables = React.useContext(LookupTablesContext);
  const [lastParcellationOverlayType, setLastParcellationOverlayType] = React.useState<OverlayType>();

  React.useEffect(() => {
    if (overlayParameters.boundaryType !== 'sulcigyri') {
      setLastParcellationOverlayType(overlayType);
    }
  }, [overlayParameters]);

  let overlayType = getOverlayTypeFromBoundary(overlayParameters.boundaryType);
  if (overlayParameters.boundaryType === 'sulcigyri') {
    overlayType = lastParcellationOverlayType ?? 'd99_12a_parcellation';
  }

  const colorMap = getParcellationColorMap(lookupTables, overlayType);
  const imagePath = getConnectionParcellationImageName(overlayType);

  return (
    <>
      {imagePath && colorMap && <RegionLabel
        point={point}
        imagePath={`${lookupTables.pathImagesConnection2d}/${imagePath}`}
        imageHeight={CONNECTIONS_2D_HEIGHT}
        imageWidth={CONNECTIONS_2D_WIDTH}
        colorMap={colorMap}
      />}
    </>
  );
}

export default ConnectionRegionLabel;
