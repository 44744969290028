import { SlicePosition } from "./SlicePosition";

export class Point3D {
  i: number = 0;
  j: number = 0;
  k: number = 0;

  constructor(slicePosition?: SlicePosition) {
    if (slicePosition != null) {
      this.i = slicePosition.coronal;
      this.k = slicePosition.sagittal;
      this.j = slicePosition.axial;
    }
  }
}
