import React from 'react'
import { LOOKUP_BASE_PATH } from '../../constants/constants';
import { LookupTables } from '../../contexts/LookupTablesContext';
import { ColorMapEntry } from '../../types/ColorMap';
import { fetchData } from '../../utils/utils';

function DebugColorRange() {
  const [lookupTables, setLookupTables] = React.useState<LookupTables>(new LookupTables());
  const imageRef = React.useRef<HTMLImageElement>(null);
  let imageUrl = "";

  // This is all copied from Sites.tsx, but this is throwaway code
  React.useEffect(() => {
    // Load and parse the color lookup table. This maps an index (the grayscale color
    // value from an image) to an actual RGB color
    const fetchColorLUTConnectionData = async () => {
      const lines = await fetchData(`${LOOKUP_BASE_PATH}/ColorLUT-Connection.csv`);
      const colorMap = new Map<number, ColorMapEntry>();
      lines.forEach((line) => {
        const lineItems = line.split(",").map((x) => parseInt(x));
        colorMap.set(lineItems[0], new ColorMapEntry({ r: lineItems[1], g: lineItems[2], b: lineItems[3] }));
      });
      setLookupTables((previousTable) => {
        return { ...previousTable, connectionsColorMap: colorMap };
      });
    };

    const fetchColorLUTParcellationData = async () => {
      const lines = await fetchData(`${LOOKUP_BASE_PATH}/ColorLabelLUT-Parcellation-CBCetal15.csv`);
      const colorMap = new Map<number, ColorMapEntry>();
      lines.forEach((line) => {
        // The last item in the lookup table (the long description) is wrapped in quotes and can
        // contain embedded commas. Rather than do more general-purpose CSV parsing here, we're
        // going to assume that only the last item is treated that way, and that it's always
        // treated that way
        const lineItems = line.split(",", 5);
        const index = parseInt(lineItems[0]);
        const r = parseInt(lineItems[1]);
        const g = parseInt(lineItems[2]);
        const b = parseInt(lineItems[3]);
        const label = lineItems[4];
        const longLabel = line.substring(line.indexOf('"')).replaceAll('"', "");
        colorMap.set(index, new ColorMapEntry({ r: r, g: g, b: b }, label, longLabel));
      });
      setLookupTables((previousTable) => {
        return { ...previousTable, parcellationColorMap: colorMap };
      });
    };
    fetchColorLUTConnectionData();
    fetchColorLUTParcellationData();
  }, []);

  // const colorCount = 239;
  const colorCount = 239;
  const colorBandHeight = 3;
  const colorWidth = 100;
  const colorHeight = colorCount * colorBandHeight;

  const canvas = document.createElement("canvas");
  canvas.height = colorCount * colorBandHeight;
  canvas.width = colorWidth;
  const context = canvas.getContext("2d");
  if (context) {
    const imageData = context.getImageData(0, 0, colorWidth, colorHeight);
    if (imageData) {
      for (var i = 0; i < imageData.data.length; i += 4) {
        const pixelPosition = Math.trunc(i/4);
        const lookupPosition = Math.trunc(pixelPosition / (colorWidth * colorBandHeight)) + 1;
        const color =  lookupTables?.connectionsColorMap?.get(lookupPosition);
        if (Math.trunc(pixelPosition / (colorWidth * colorBandHeight)) === pixelPosition / (colorWidth * colorBandHeight)) {
          console.log(color);
        }
        if (color) {
          imageData.data[i] = color.pixel.r;
          imageData.data[i+1] = color.pixel.g;
          imageData.data[i+2] = color.pixel.b;
          imageData.data[i+3] = 255;
        }
        if (Math.trunc(pixelPosition / (colorWidth * colorBandHeight)) === pixelPosition / (colorWidth * colorBandHeight)) {
          console.log(color?.pixel);
          console.table([imageData.data[i],imageData.data[i+1],imageData.data[i+2],imageData.data[i+3],])
        }
      }
      context.putImageData(imageData, 0, 0);
      imageUrl = canvas?.toDataURL();
    }
  }

  return (
    <div>
      <img src={imageUrl} alt="" ref={imageRef} draggable="false"/>
      </div>
  )
}

export default DebugColorRange