import React from 'react'
import DebugColorRange from '../../components/DebugColorRange/DebugColorRange'

function Debug() {
  return (
    <div>
      <h1>Debug</h1>
      <DebugColorRange />
    </div>
  )
}

export default Debug