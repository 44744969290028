import React from "react";
import LookupTablesContext from "../../contexts/LookupTablesContext";
import { InjectionSite } from "../../types/InjectionSite";
import { OverlayParameters } from "../../types/OverlayParameters";
import { Point2D } from "../../types/Point2D";
import { blend, isLowPixelValue } from "../../utils/utils";
import CombinerCanvas, { InputImages } from "../CombinerCanvas/CombinerCanvas";

type Props = {
  injectionSites: InjectionSite[];
  canvasSize?: number;
  overlayParameters?: OverlayParameters;
  onSetClickedPoint?: (point: Point2D, shiftKey: boolean) => void;
  onSetMousePosition?: (point: Point2D) => void;
  onSetImageData?: (data: string) => void;
};

function Connection2DCanvas({
  injectionSites,
  canvasSize = 500,
  overlayParameters = { boundaryType: "d99_12a", overlayType: "connections", overlayColorLookupType: "connections", opacity: 1 },
  onSetClickedPoint,
  onSetMousePosition,
  onSetImageData,
}: Props) {
  const lookupTables = React.useContext(LookupTablesContext);

  // Assuming that the image is square
  const combineBackgroundImageWithOverlayAndBorders = (
    contexts: CanvasRenderingContext2D[]
  ): HTMLCanvasElement | undefined => {
    const backgroundImage = contexts[0].getImageData(0, 0, canvasSize, canvasSize);
    const borderImage = contexts[1].getImageData(0, 0, canvasSize, canvasSize);
    const colorImages = contexts.slice(2).map((context) => context.getImageData(0, 0, canvasSize, canvasSize));

    const resultCanvas = document.createElement("canvas");
    resultCanvas.width = canvasSize;
    resultCanvas.height = canvasSize;
    const resultContext = resultCanvas.getContext("2d");
    if (!resultContext) {
      console.error("Could not create blended image");
      return;
    }
    const resultImage = resultContext.getImageData(0, 0, canvasSize, canvasSize);

    // get the pixel data as array
    const backgroundData = backgroundImage.data;
    const borderData = borderImage.data;
    const resultData = resultImage.data;
    const colorMap =
      overlayParameters?.overlayColorLookupType === "connections"
        ? lookupTables.connectionsColorMap
        : lookupTables.parcellationColorMaps?.get(overlayParameters.overlayColorLookupType);

    // If there is color data, display it over the background but under the boundary
    for (var i = 0; i < backgroundData.length; i += 4) {
      let r = backgroundData[i];
      let g = backgroundData[i + 1];
      let b = backgroundData[i + 2];
      if (colorMap) {
        // Check to see if there is data in the color data image for this pixel. We can check by looking
        // only at the "r" pixel, because the color data is grayscale and the r/g/b numbers all have the
        // same value. Since we have multiple overlays, we take the AVERAGE value from the colorData table
        let overlayValue: number = 0;
        if (colorImages.length > 0) {
          for (const colorImage of colorImages) {
            const colorData = colorImage.data;
            overlayValue += colorData[i];
          }
          overlayValue = Math.floor(overlayValue / colorImages.length);
        }
        // Give the highest pixel value from the overlay, lookup the corresponding color and blend it
        // with the background
        if (!isLowPixelValue(overlayValue)) {
          const pixel = colorMap.get(overlayValue)?.pixel;
          if (pixel) {
            r = blend(r, pixel.r, overlayParameters.opacity);
            g = blend(g, pixel.g, overlayParameters.opacity);
            b = blend(b, pixel.b, overlayParameters.opacity);
          }
        }
      }

      // Blend the resulting image with the boundaries
      resultData[i] = blend(r, borderData[i], borderData[i + 3] / 255);
      resultData[i + 1] = blend(g, borderData[i + 1], borderData[i + 3] / 255);
      resultData[i + 2] = blend(b, borderData[i + 2], borderData[i + 3] / 255);
      resultData[i + 3] = 255 - ((255 - backgroundData[i + 3]) * (255 - borderData[i + 3])) / 255;
    }

    // Draw it on the canvas, and return the canvas
    resultContext.putImageData(resultImage, 0, 0);
    return resultCanvas;
  };

  const urls: string[] = [];
  // Add the background image
  urls.push(`${lookupTables.pathImagesConnection2d}/Conn2D-Background.View-Ipsi_Flat.png`);
  // Add the borders
  switch (overlayParameters.boundaryType) {
    case "atlas":
      urls.push(`${lookupTables.pathImagesConnection2d}/Conn2D-Boundary-CBCetal15.View-Ipsi_Flat.png`);
      break;
    case "sulcigyri":
      urls.push(`${lookupTables.pathImagesConnection2d}/Conn2D-Boundary-Sulci_Gyri.View-Ipsi_Flat.png`);
      break;
    case "d99_12a":
      urls.push(`${lookupTables.pathImagesConnection2d}/Conn2D-Boundary-D99_12a.View-Ipsi_Flat.png`);
      break;
    case "charm_lv1":
      urls.push(`${lookupTables.pathImagesConnection2d}/Conn2D-Boundary-CHARM_lv1.View-Ipsi_Flat.png`);
      break;
    case "charm_lv2":
      urls.push(`${lookupTables.pathImagesConnection2d}/Conn2D-Boundary-CHARM_lv2.View-Ipsi_Flat.png`);
      break;
    case "charm_lv3":
      urls.push(`${lookupTables.pathImagesConnection2d}/Conn2D-Boundary-CHARM_lv3.View-Ipsi_Flat.png`);
      break;
    case "charm_lv4":
      urls.push(`${lookupTables.pathImagesConnection2d}/Conn2D-Boundary-CHARM_lv4.View-Ipsi_Flat.png`);
      break;
    case "charm_lv5":
      urls.push(`${lookupTables.pathImagesConnection2d}/Conn2D-Boundary-CHARM_lv5.View-Ipsi_Flat.png`);
      break;
    case "charm_lv6":
      urls.push(`${lookupTables.pathImagesConnection2d}/Conn2D-Boundary-CHARM_lv6.View-Ipsi_Flat.png`);
      break;
  }
  switch (overlayParameters.overlayType) {
    case "connections":
      // Add the overlays specific to the injection sites
      urls.push(...injectionSites.map((s) => s.getImage()));
      break;
    case "atlas_parcellation":
      // Add the atlas (CBCetal15) parcellation overlay
      urls.push(`${lookupTables.pathImagesConnection2d}/Conn2D-Parcellation-CBCetal15.View-Ipsi_Flat.png`);
      break;
    case "d99_12a_parcellation":
      // Add the atlas (D99_12s) parcellation overlay
      urls.push(`${lookupTables.pathImagesConnection2d}/Conn2D-Parcellation-D99_12a.View-Ipsi_Flat.png`);
      break;
    case "charm_lv1_parcellation":
      urls.push(`${lookupTables.pathImagesConnection2d}/Conn2D-Parcellation-CHARM_lv1.View-Ipsi_Flat.png`);
      break;
    case "charm_lv2_parcellation":
      urls.push(`${lookupTables.pathImagesConnection2d}/Conn2D-Parcellation-CHARM_lv2.View-Ipsi_Flat.png`);
      break;
    case "charm_lv3_parcellation":
      urls.push(`${lookupTables.pathImagesConnection2d}/Conn2D-Parcellation-CHARM_lv3.View-Ipsi_Flat.png`);
      break;
    case "charm_lv4_parcellation":
      urls.push(`${lookupTables.pathImagesConnection2d}/Conn2D-Parcellation-CHARM_lv4.View-Ipsi_Flat.png`);
      break;
    case "charm_lv5_parcellation":
      urls.push(`${lookupTables.pathImagesConnection2d}/Conn2D-Parcellation-CHARM_lv5.View-Ipsi_Flat.png`);
      break;
    case "charm_lv6_parcellation":
      urls.push(`${lookupTables.pathImagesConnection2d}/Conn2D-Parcellation-CHARM_lv6.View-Ipsi_Flat.png`);
      break;
  }
  const inputImages: InputImages = {
    width: canvasSize,
    height: canvasSize,
    urls: urls,
    combine: combineBackgroundImageWithOverlayAndBorders,
  };

  return (
    <>
      <CombinerCanvas
        inputImages={inputImages}
        onSetClickedPoint={onSetClickedPoint}
        onSetMousePosition={onSetMousePosition}
        onSetImageData={onSetImageData}
      />
    </>
  );
}

export default Connection2DCanvas;
