import React from "react";
import {
    Link
  } from "react-router-dom";
import styles from "./Home.module.css";
import background from "../../assets/images/background.png";

function Home() {
    return (
        <div className={styles.wrapper} style={{backgroundImage: 'url(' + background + ')'}}>
            <h1>Explore parcellation-free dense connectome of individual macaques</h1>
            <p>… mapped by electrical microstimulation at densely sampled sites and concurrent, brainwide readout of stimulation-elicited functional MRI responses.</p>
            <Link to="/nmt">Data on NMT (a population template)</Link>
            <Link to="/xbtdn">Data on XBTDN (an individual brain)</Link>
        </div>
    );
  }
  
  export default Home;
  