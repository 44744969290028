import React from 'react'
import styles from "./ConnectionsHelp.module.css";

function ConnectionsHelp() {
  return (
    <div className={styles.wrapper}>
      <h2>To Explore</h2>
      <p>Click any <span className={styles.orange}>orange</span> or <span className={styles.blue}>blue</span> dot 
      (or hold shift and click to select multiple) to see corresponding 3D images and Connections Map.</p>
      <p>Click elsewhere on stimulation surface to see corresponding 3D images.</p>
      <p>Questions? <a href="mailto:ruix@mit.edu">ruix@mit.edu</a></p>
    </div>
  )
}

export default ConnectionsHelp