import React from "react";
import { Link, NavLink } from "react-router-dom";
import AppNameContext from "../../contexts/AppNameContext";
import styles from "./Header.module.css";

const showSafariWarning = () => {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf("safari") !== -1) {
    if (ua.indexOf("chrome") > -1) {
      // Chrome
    } else {
      // Safari
      return true;
    }
  }
  return false;
}

function Header() {
  return (
    <>
    <header className={styles.Header}>
      <Link to="/" className={styles.home}>
        {React.useContext(AppNameContext)}
      </Link>
      <nav>
        <ul className={styles.menu}>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? styles.active : "")}
              to="/about"
            >
              About
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? styles.active : "")}
              to="/nmt"
            >
              Data on NMT
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? styles.active : "")}
              to="/xbtdn"
            >
              Data on XBTDn
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
    { showSafariWarning() && <div className={styles.safari}>For best results, we recommend using Chrome or Firefox instead of Safari</div> }
    </>
  );
}

export default Header;
