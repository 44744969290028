import React from "react";
import { SITE_2D_HEIGHT, SITE_2D_WIDTH } from "../../constants/constants";
import LookupTablesContext from "../../contexts/LookupTablesContext";
import { BoundaryType } from "../../types/OverlayParameters";
import { Point2D } from "../../types/Point2D";
import { getParcellationColorMapFromBoundary, getSiteParcellationImageName } from "../../utils/lookup";
import RegionLabel from "../RegionLabel/RegionLabel";

type Props = {
  point: Point2D | null;
  boundary: BoundaryType;
};

// Use the Lookup table and associated image to find the region of the brain that corresponds
// to a specific point
function SiteRegionLabel({ point, boundary }: Props) {
  const lookupTables = React.useContext(LookupTablesContext);

  let colorMap = getParcellationColorMapFromBoundary(lookupTables, boundary);
  const imageName = getSiteParcellationImageName(boundary);

  return (
    <>
      {colorMap && (
        <RegionLabel
          point={point}
          imagePath={`${lookupTables.pathImagesSite2d}/${imageName}`}
          imageHeight={SITE_2D_HEIGHT}
          imageWidth={SITE_2D_WIDTH}
          colorMap={colorMap}
        />
      )}
    </>
  );
}

export default SiteRegionLabel;
