import React from "react";
import { Point2D } from "../../types/Point2D";
import { getElementPointFromClickPosition } from "../../utils/utils";
import styles from "./Canvas.module.css";

type Props = {
  height: number;
  width: number;
  imageUrl: string;
  onSetClickedPoint?: (point: Point2D, shiftKey: boolean) => void;
  onSetMousePosition?: (point: Point2D) => void;
};

function Canvas({ height, width, imageUrl, onSetClickedPoint, onSetMousePosition }: Props) {
  // Reference to the canvas that is being clicked on
  const imageRef = React.useRef<HTMLImageElement>(null);

  // Calculate the canonical position on the image basd on where the user clicked
  // TODO: Scaling based on the image size
  const getPointFromMousePosition = (event: React.MouseEvent) => {
    if (imageRef.current) {
      return getElementPointFromClickPosition(event.clientX, event.clientY, imageRef.current);
    }
  };

  // Report a mouse click to the enclosing element
  const handleCanvasClick: React.MouseEventHandler<HTMLImageElement> = (event) => {
    if (onSetClickedPoint) {
      const point = getPointFromMousePosition(event);
      if (point) {
        onSetClickedPoint(point, event.shiftKey);
      }
    }
  };

  // Report the mouse position to the enclosing element
  const handleMouseMove = (event: React.MouseEvent) => {
    if (onSetMousePosition) {
      const point = getPointFromMousePosition(event);
      if (point) {
        onSetMousePosition(point);
      }
    }
  };

  return (
    <>
      {imageUrl && 
        <img src={imageUrl} alt="" onClick={handleCanvasClick} onMouseMove={handleMouseMove} ref={imageRef} draggable="false"/>
      }
      {!imageUrl && 
        <div className={styles.loading}></div>
      }
    </>
  );
}

export default Canvas;
