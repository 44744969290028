import React from "react";
import { ColorMap } from "../../types/ColorMap";
import { Point2D } from "../../types/Point2D";
import { createImage } from "../../utils/utils";
import styles from "./RegionLabel.module.css";

type Props = {
  point: Point2D | null;
  imagePath: string;
  imageHeight: number;
  imageWidth: number;
  colorMap?: ColorMap;
};

function RegionLabel({ point, imagePath, imageHeight, imageWidth, colorMap }: Props) {
  const [imageData, setImageData] = React.useState<ImageData>();

  React.useEffect(() => {
    // Render the image that descripes the areas to a canvas, and then save the imagedata
    const renderImage = async () => {
      const canvas = document.createElement("canvas");
      canvas.height = imageHeight;
      canvas.width = imageWidth;
      const context = canvas.getContext("2d");
      const image = await createImage(imagePath);
      context?.drawImage(image, 0, 0, canvas.width, canvas.height);
      setImageData(context?.getImageData(0, 0, canvas.width, canvas.height));
    };

    renderImage();
  }, [imageHeight, imageWidth, imagePath]);

  // Given a point, find that point on the image that describes the areas, get the color, and
  // then lookup the corresponding entry in the colormap to get the label for the area enclosing
  // that point
  const lookupLabels = (point: Point2D | null, imageData: ImageData | undefined, colorMap: ColorMap | undefined) => {
    if (point && imageData && colorMap) {
      const colorIndex = (point.y - 1) * (imageWidth * 4) + (point.x - 1) * 4;
      if (colorIndex < imageData.data.length) {
        const mappingIndex = imageData.data[colorIndex];
        const result = colorMap?.get(mappingIndex);
        return result;
      }
    }
  };

  const label = lookupLabels(point, imageData, colorMap);
  return <div className={styles.wrapper}>{label && `${label?.label}`}{label?.longLabel && `: ${label?.longLabel}`}&nbsp;</div>;
}

export default RegionLabel;
