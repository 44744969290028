/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent } from "react";
import LookupTablesContext from "../../contexts/LookupTablesContext";
import { SlicePosition } from "../../types/SlicePosition";
import { SliceType } from "../../types/SliceType";
import styles from "./PositionInfo.module.css";

// TRANSFORM SPECIFICATIONS
// I/J/K indexes voxels (3D pixels), and should be rounded to integers in web UI.
// R(ight)/A(nterior)/S(uperior) are in millimeters, and 2 digits after decimal point ('%.2f') is sufficent pecision in web UI.
// Specific transformations formulas are defined in the LookupTablesContext.

type Props = {
  slicePosition: SlicePosition | null;
  onSetSlicePosition: (position: SlicePosition) => void;
};

function PositionInfo({ slicePosition, onSetSlicePosition }: Props) {
  // eslint-disable-next-line no-unused-vars
  const [wasIJKUpdatedLast, setWasIJKUpdatedLast] = React.useState<boolean>(true);
  const [oldIValue, setOldIValue] = React.useState<number>(slicePosition?.coronal || 0);
  const [oldJValue, setOldJValue] = React.useState<number>(slicePosition?.axial || 0);
  const [oldKValue, setOldKValue] = React.useState<number>(slicePosition?.sagittal || 0);
  const [displayIValue, setDisplayIValue] = React.useState<string>((slicePosition?.coronal || 0).toString());
  const [displayJValue, setDisplayJValue] = React.useState<string>((slicePosition?.axial || 0).toString());
  const [displayKValue, setDisplayKValue] = React.useState<string>((slicePosition?.sagittal || 0).toString());
  const [displayRValue, setDisplayRValue] = React.useState<string>((slicePosition?.sagittal || 0).toString());
  const [displayAValue, setDisplayAValue] = React.useState<string>((slicePosition?.coronal || 0).toString());
  const [displaySValue, setDisplaySValue] = React.useState<string>((slicePosition?.axial || 0).toString());
  const [forceRecalc, setForceRecalc] = React.useState<boolean>(false);
  const lookupTables = React.useContext(LookupTablesContext);


  if (slicePosition && (forceRecalc || slicePosition?.sagittal !== oldKValue || slicePosition?.coronal !== oldIValue || slicePosition?.axial !== oldJValue)) {
    setOldIValue(slicePosition?.coronal || 0);
    setDisplayIValue((slicePosition?.coronal || 0).toString());
    setDisplayAValue(slicePosition?.coronal ? (lookupTables.itoATransform(slicePosition.coronal)).toFixed(2).toString() : "");

    setOldJValue(slicePosition?.axial || 0);
    setDisplayJValue((slicePosition?.axial || 0).toString());
    setDisplaySValue(slicePosition?.axial ? (lookupTables.jtoSTransform(slicePosition.axial)).toFixed(2).toString() : "");

    setOldKValue(slicePosition.sagittal);
    setDisplayKValue(slicePosition.sagittal.toString());
    setDisplayRValue(slicePosition?.sagittal ? (lookupTables.ktoRTransform(slicePosition.sagittal)).toFixed(2).toString() : "");

    setForceRecalc(false);
  }

  const testSliceNumber = (newSliceNumber: number, type: SliceType): boolean => {
    return !Number.isNaN(newSliceNumber) && newSliceNumber > 0 && newSliceNumber <= lookupTables.sliceCountMap.get(type)!;
  };

  const handleRChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDisplayRValue(event.target.value);
    setWasIJKUpdatedLast(false);
  };
  const handleAChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDisplayAValue(event.target.value);
    setWasIJKUpdatedLast(false);
  };
  const handleSChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDisplaySValue(event.target.value);
    setWasIJKUpdatedLast(false);
  };

  const handleIChange = (event: ChangeEvent<HTMLInputElement>) => {
    const n = Number.parseInt(event.target.value);
    if (testSliceNumber(n, "coronal")) {
      setDisplayIValue(n.toString());
    }
    setWasIJKUpdatedLast(true);
  };
  const handleJChange = (event: ChangeEvent<HTMLInputElement>) => {
    const n = Number.parseInt(event.target.value);
    if (testSliceNumber(n, "axial")) {
      setDisplayJValue(n.toString());
    }
    setWasIJKUpdatedLast(true);
  };
  const handleKChange = (event: ChangeEvent<HTMLInputElement>) => {
    const n = Number.parseInt(event.target.value);
    if (testSliceNumber(n, "sagittal")) {
      setDisplayKValue(n.toString());
    }
    setWasIJKUpdatedLast(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      handleChange(event);
    }
  }

  const handleChange = (event: any) => {
    let i,j,k = null;
    if (wasIJKUpdatedLast) {
      i = Number.parseInt(displayIValue);
      j = Number.parseInt(displayJValue);
      k = Number.parseInt(displayKValue);
    } else {
      i = lookupTables.atoITransform(Number.parseFloat(displayAValue));
      j = lookupTables.stoJTransform(Number.parseFloat(displaySValue));
      k = lookupTables.rtoKTransform(Number.parseFloat(displayRValue));
    }
    if (testSliceNumber(i, "coronal") && testSliceNumber(j, "axial") && testSliceNumber(k, "sagittal")) {
      onSetSlicePosition({
       "sagittal": k,
       "coronal": i,
       "axial": j
       });
    } else {
      // Resest to the last valid value
      onSetSlicePosition({
        sagittal: oldKValue,
        coronal: oldIValue,
        axial: oldJValue
      })
    }
    setForceRecalc(true);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.rasPositionWrapper}>
        <label>R / A / S</label>
        <div className={styles.inputWrapper}>
          <input value={displayRValue} onChange={handleRChange} onKeyDown={handleKeyDown} className={styles.ijkInput}  />
          <span className={styles.separator}>/</span>
          <input value={displayAValue} onChange={handleAChange} onKeyDown={handleKeyDown} className={styles.ijkInput}  />
          <span className={styles.separator}>/</span>
          <input value={displaySValue} onChange={handleSChange} onKeyDown={handleKeyDown} className={styles.ijkInput}  />
        </div>

        <span>mm</span>
      </div>
      <div className={styles.ijkPositionWrapper}>
        <label>I / J / K</label>
        <div className={styles.inputWrapper}>
          <input value={displayIValue} onChange={handleIChange} onKeyDown={handleKeyDown} className={styles.ijkInput} />
          <span className={styles.separator}>/</span>
          <input value={displayJValue} onChange={handleJChange} onKeyDown={handleKeyDown} className={styles.ijkInput} />
          <span className={styles.separator}>/</span>
          <input value={displayKValue} onChange={handleKChange} onKeyDown={handleKeyDown} className={styles.ijkInput} />
        </div>
        <button className={styles.goButton} onClick={handleChange}>Go</button>
      </div>
    </div>
  );
}

export default PositionInfo;
