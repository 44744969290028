import React from "react";
import BrainSlices from "../BrainSlices/BrainSlices";
import PaneHeader from "../PaneHeader/PaneHeader";
import StimulationMap from "../StimulationMap/StimulationMap";
import StimulationInfo from "../StimulationInfo/StimulationInfo";
import styles from "./StimulationSites.module.css";
import { SlicePosition } from "../../types/SlicePosition";
import { InjectionSite } from "../../types/InjectionSite";
import { Point2D } from "../../types/Point2D";
import { BoundaryType } from "../../types/OverlayParameters";
import { Point3D } from "../../types/Point3D";
import LookupTablesContext from "../../contexts/LookupTablesContext";

type Props = {
  onSetInjectionSites: (injectionSites: InjectionSite[]) => void;
  activeSites: string[] | null;
};

function StimulationSites({ onSetInjectionSites, activeSites }: Props) {
  const [slicePosition, setSlicePosition] = React.useState<SlicePosition | null>(null);
  const [selectedPoint, setSelectedPoint] = React.useState<Point2D | null>(null);
  const [boundary, setBoundary] = React.useState<BoundaryType>("d99_12a");
  const lookupTables = React.useContext(LookupTablesContext);

  const handleSetSlicePosition = (slicePosition: SlicePosition) => {
    setSlicePosition(slicePosition);
    const lookupResult = lookupTables.connections3Dto2DMap?.get(new Point3D(slicePosition));
    setSelectedPoint(lookupResult || null);
  };

  const showSlices = (s: SlicePosition | null) => {
    if (s) {
      return (
        <div className={styles.column}>
          <BrainSlices slicePosition={s} onSetSlicePosition={handleSetSlicePosition} />
        </div>
      );
    } else {
      return <div className={styles.column}></div>;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.column}>
        <PaneHeader title={"Stimulation Sites"} displayStyle="yellow" />
        <div className={styles.mapInfoWrapper}>
          <StimulationMap
            slicePosition={slicePosition}
            activeSites={activeSites}
            boundary={boundary}
            onSetSlicePosition={setSlicePosition}
            onSetInjectionSites={onSetInjectionSites}
            onSetSelectedPoint={setSelectedPoint}
          />
          <StimulationInfo
            slicePosition={slicePosition}
            selectedPoint={selectedPoint}
            boundary={boundary}
            onSetSlicePosition={handleSetSlicePosition}
            onSetBoundary={setBoundary}
          />

        </div>
      </div>
      {showSlices(slicePosition)}
    </div>
  );
}

export default StimulationSites;
