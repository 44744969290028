export const BASE_PATH_DATA = `${process.env.PUBLIC_URL}/website_data`;
export const BASE_PATH_SITE2D = `${BASE_PATH_DATA}/Images-Site2D`;
export const BASE_PATH_3D = `${BASE_PATH_DATA}/Images-3D`;

export const BASE_PATH_PREGENERATED = `${process.env.PUBLIC_URL}/pregenerated`;

export const BASE_PATH_CONNECTIONS2D = `${BASE_PATH_DATA}/Images-Conn2D`;
export const LOOKUP_BASE_PATH = `${BASE_PATH_DATA}/LookupTables`;

export const SITE_2D_WIDTH = 461;
export const SITE_2D_HEIGHT = 591;

export const CONNECTIONS_2D_WIDTH = 500;
export const CONNECTIONS_2D_HEIGHT = 500;

export const NMT_PATH_FRAGMENT = 'Template-NMT20sym';
export const XBTDN_PATH_FRAGMENT = 'Template-XBTD22n';

export const GTM_ID = 'GTM-5FCXQFXZ';