import React from "react";
import LookupTablesContext from "../../contexts/LookupTablesContext";
import { BoundaryType } from "../../types/OverlayParameters";
import styles from "./SiteControls.module.css";

type Props = {
  boundary?: BoundaryType;
  onSetBoundary: (b: BoundaryType) => void;
};

function SiteControls({ boundary, onSetBoundary }: Props) {
  const lookupTables = React.useContext(LookupTablesContext);

  const availableBoundaries = lookupTables.availableStimulationSitesBoundaries;
  const handleBoundaryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value && boundary) {
      onSetBoundary(e.target.value as BoundaryType);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <label className={styles.label}>Boundaries</label>
        <div className={styles.controlWrapper}>
          <select value={boundary} onChange={handleBoundaryChange}>
            {availableBoundaries.includes('atlas') && <option value="atlas">CBCetal15</option>}
            {availableBoundaries.includes('d99_12a') && <option value="d99_12a">D99_12a</option>}
            {availableBoundaries.includes('charm_lv1') && <option value="charm_lv1">CHARM_l1</option>}
            {availableBoundaries.includes('charm_lv2') && <option value="charm_lv2">CHARM_l2</option>}
            {availableBoundaries.includes('charm_lv3') && <option value="charm_lv3">CHARM_l3</option>}
            {availableBoundaries.includes('charm_lv4') && <option value="charm_lv4">CHARM_l4</option>}
            {availableBoundaries.includes('charm_lv5') && <option value="charm_lv5">CHARM_l5</option>}
            {availableBoundaries.includes('charm_lv6') && <option value="charm_lv6">CHARM_l6</option>}
          </select>
        </div>
      </div>
    </div>
  );
}

export default SiteControls;
