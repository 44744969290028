import React from "react";
import LookupTablesContext from "../../contexts/LookupTablesContext";
import { getCoronalActivityPointsDataForSlice } from "../../utils/lookup";
import { InjectionSite } from "../../types/InjectionSite";
import { OverlayParameters } from "../../types/OverlayParameters";
import { Point2D } from "../../types/Point2D";
import { Point3D } from "../../types/Point3D";
import { SlicePosition } from "../../types/SlicePosition";
import BrainSlices from "../BrainSlices/BrainSlices";
import ColorBar from "../ColorBar/ColorBar";
import ConnectionControls from "../ConnectionControls/ConnectionControls";
import ConnectionInfo from "../ConnectionInfo/ConnectionInfo";
import ConnectionMap from "../ConnectionMap/ConnectionMap";
import PaneHeader from "../PaneHeader/PaneHeader";
import styles from "./Connections.module.css";

type Props = {
  injectionSites?: InjectionSite[];
  onSetActiveSites: (sites: string[] | null) => void;
};

function Connections({ injectionSites, onSetActiveSites }: Props) {
  const [slicePosition, setSlicePosition] = React.useState<SlicePosition | null>(null);
  const [selectedPoint, setSelectedPoint] = React.useState<Point2D | null>(null);
  const [retrogradeSelectedPoint, setRetrogradeSelectedPoint] = React.useState<Point2D | null>(null);
  const [retrogradeSlicePosition, setRetrogradeSlicePosition] = React.useState<SlicePosition | undefined>(undefined);
  const [overlayParameters, setOverlayParameters] = React.useState<OverlayParameters>({
    overlayType: "connections",
    boundaryType: "d99_12a",
    overlayColorLookupType: "connections",
    opacity: 1,
  });
  const lookupTables = React.useContext(LookupTablesContext);

  const showSlices = (s: SlicePosition | null) => {
    if (s && injectionSites?.length) {
      return (
        <div className={styles.column}>
          <BrainSlices
            slicePosition={s}
            retrogradeSlicePosition={retrogradeSlicePosition}
            onSetSlicePosition={handleSetSlicePosition}
            onSetRetrogradeSlicePosition={handleSetRetrogradeSlicePosition}
            injectionSites={injectionSites}
            overlayParameters={overlayParameters}
          />
          <ColorBar />
        </div>
      );
    } else {
      return <div className={styles.column}><ColorBar /></div>;
    }
  };

  const setActiveSitesFromSlicePosition = (slicePosition: SlicePosition|null) => {
    if (lookupTables.pathImagesPregenerated && slicePosition) {
      getCoronalActivityPointsDataForSlice(lookupTables.pathImagesPregenerated, slicePosition?.coronal)
      .then((data) => {
        const coronalPoint: Point2D = { x: slicePosition.sagittal, y: slicePosition.axial };
        onSetActiveSites(data?.get(coronalPoint) ?? []);
      })
    } else {
      onSetActiveSites(null);
    }
  }

  const handleSetSlicePosition = (slicePosition: SlicePosition) => {
    setSlicePosition(slicePosition);
    const lookupResult = lookupTables.connections3Dto2DMap?.get(new Point3D(slicePosition));
    setSelectedPoint(lookupResult || null);
  };

  const handleSetRetrogradeSlicePosition = (slicePosition: SlicePosition) => {
    const lookupResult = lookupTables.connections3Dto2DMap?.get(new Point3D(slicePosition));

    setActiveSitesFromSlicePosition(slicePosition);

    // This is {0,0} (selected, but no retrograde sites), not NULL (same as if selection point was cleared) as per #6005
    setRetrogradeSelectedPoint(lookupResult || {x: 0, y: 0});

    // The function on the previous line set the slice position based on the point that it mapped to, but we want to
    // keep the original slice position that was passed into this function, since the round trip from
    // slice position A --> point --> slice position B may not be exactly the same as slice position A
    setRetrogradeSlicePosition(slicePosition);
  };

  const handleSetSelectedPoint = (point: Point2D) => {
    setSlicePosition(new SlicePosition(lookupTables.connections2Dto3DMap?.get(point)));
    setSelectedPoint(point);
  };

  const handleSetRetrogradeSelectionPoint = (point: Point2D | null) => {
    setRetrogradeSelectedPoint(point);
    const retrogradeSlicePosition = point ? new SlicePosition(lookupTables.connections2Dto3DMap?.get(point)): undefined;
    setRetrogradeSlicePosition(retrogradeSlicePosition);
    setActiveSitesFromSlicePosition(retrogradeSlicePosition ?? null);

  };


  return (
    <div className={styles.wrapper}>
      <div className={styles.column}>
        <PaneHeader
          title={"Connections"}
          displayStyle="blue"
        />
        <ConnectionMap
          injectionSites={injectionSites}
          selectedPoint={selectedPoint}
          retrogradeSelectedPoint={retrogradeSelectedPoint}
          onSetSelectedPoint={handleSetSelectedPoint}
          onSetRetrogradeSelectionPoint={handleSetRetrogradeSelectionPoint}
          overlayParameters={overlayParameters}
        />
        <ConnectionInfo
          slicePosition={slicePosition}
          selectedPoint={selectedPoint}
          onSetSlicePosition={handleSetSlicePosition}
          overlayParameters={overlayParameters}
        />
        <ConnectionControls overlayParameters={overlayParameters} onSetOverlayParameters={setOverlayParameters} />
      </div>
      {showSlices(slicePosition)}
    </div>
  );
}

export default Connections;
