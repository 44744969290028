import React from 'react'
import { OverlayParameters } from '../../types/OverlayParameters';
import { Point2D } from '../../types/Point2D';
import { SlicePosition } from '../../types/SlicePosition';
import PositionInfo from '../PositionInfo/PositionInfo';
import styles from "./ConnectionInfo.module.css";
import RegionLabel3D from '../RegionLabel3D/RegionLabel3D';

type Props = {
  slicePosition: SlicePosition|null;
  selectedPoint: Point2D|null;
  onSetSlicePosition: (position: SlicePosition) => void;
  overlayParameters: OverlayParameters;
}

function ConnectionInfo({slicePosition, selectedPoint, onSetSlicePosition, overlayParameters}: Props) {
  return (
    <div className={styles.wrapper}>
      <h3>Selected Coordinates</h3>
      <div className={styles.connectionRegionLabelWrapper}>
        {slicePosition && ( 
            <RegionLabel3D  slicePosition={slicePosition} overlayType={overlayParameters.overlayType} />
          )}
      </div>
      <PositionInfo slicePosition={slicePosition} onSetSlicePosition={onSetSlicePosition} />
    </div>
  )
  }

export default ConnectionInfo