import React, { ChangeEvent } from "react";
import LookupTablesContext from "../../contexts/LookupTablesContext";
import { BoundaryType, OverlayColorLookupType, OverlayParameters, OverlayType } from "../../types/OverlayParameters";
import styles from "./ConnectionControls.module.css";

type Props = {
  overlayParameters?: OverlayParameters;
  onSetOverlayParameters: (p: OverlayParameters) => void;
};

function ConnectionControls({ overlayParameters, onSetOverlayParameters }: Props) {
  const lookupTables = React.useContext(LookupTablesContext);

  const availableParcellations = lookupTables.availableConnectionsParcellations;
  const availableBoundaries = lookupTables.availableStimulationSitesBoundaries

  const handleBoundaryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value && overlayParameters) {
      onSetOverlayParameters({ ...overlayParameters, boundaryType: e.target.value as BoundaryType });
    }
  };

  const handleOverlayChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value && overlayParameters) {
      let overlayColorLookupType;
      overlayColorLookupType = e.target.value as OverlayColorLookupType;
      onSetOverlayParameters({
        ...overlayParameters,
        overlayType: e.target.value as OverlayType,
        overlayColorLookupType: overlayColorLookupType || "connections",
      });
    }
  };

  const handleOpacityChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value && overlayParameters) {
      onSetOverlayParameters({ ...overlayParameters, opacity: Number.parseFloat(e.target.value) });
    }
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <label className={styles.label}>Boundaries</label>
        <div className={styles.controlWrapper}>
          <select value={overlayParameters?.boundaryType} onChange={handleBoundaryChange}>
            {availableBoundaries.includes("d99_12a") && <option value="d99_12a">D99_12a</option>}
            {availableBoundaries.includes("sulcigyri") && <option value="sulcigyri">Sulci-Gyri</option>}
            {availableBoundaries.includes("atlas") && <option value="atlas">CBCetal15</option>}
            {availableBoundaries.includes("charm_lv1") && <option value="charm_lv1">CHARM_l1</option>}
            {availableBoundaries.includes("charm_lv2") && <option value="charm_lv2">CHARM_l2</option>}
            {availableBoundaries.includes("charm_lv3") && <option value="charm_lv3">CHARM_l3</option>}
            {availableBoundaries.includes("charm_lv4") && <option value="charm_lv4">CHARM_l4</option>}
            {availableBoundaries.includes("charm_lv5") && <option value="charm_lv5">CHARM_l5</option>}
            {availableBoundaries.includes("charm_lv6") && <option value="charm_lv6">CHARM_l6</option>}
          </select>
        </div>
      </div>
      <div>
        <label className={styles.label}>Overlay</label>
        <div className={styles.controlWrapper}>
          <select value={overlayParameters?.overlayType} onChange={handleOverlayChange}>
            {availableParcellations.includes("d99_12a_parcellation") && <option value="d99_12a_parcellation">Area-D99_12a</option>}
            {availableParcellations.includes("atlas_parcellation") && <option value="atlas_parcellation">Area-CBCetal15</option>}
            {availableParcellations.includes("charm_lv1_parcellation") && <option value="charm_lv1_parcellation">Area-CHARM_l1</option>}
            {availableParcellations.includes("charm_lv2_parcellation") && <option value="charm_lv2_parcellation">Area-CHARM_l2</option>}
            {availableParcellations.includes("charm_lv3_parcellation") && <option value="charm_lv3_parcellation">Area-CHARM_l3</option>}
            {availableParcellations.includes("charm_lv4_parcellation") && <option value="charm_lv4_parcellation">Area-CHARM_l4</option>}
            {availableParcellations.includes("charm_lv5_parcellation") && <option value="charm_lv5_parcellation">Area-CHARM_l5</option>}
            {availableParcellations.includes("charm_lv6_parcellation") && <option value="charm_lv6_parcellation">Area-CHARM_l6</option>}
            <option value="connections">Connections</option>
          </select>
        </div>{" "}
      </div>
      <div>
        <label className={styles.label}>Overlay Opacity</label>
        <div className={styles.controlWrapper}>
          <input
            type="range"
            min={0}
            max={1}
            step={0.2}
            value={overlayParameters?.opacity}
            onChange={handleOpacityChange}
          />
          <div className={styles.rangeLabels}>
            <span>0%</span>
            <span>100%</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConnectionControls;
