import React from 'react'
import LookupTablesContext from '../../contexts/LookupTablesContext';
import { BoundaryType, OverlayType } from '../../types/OverlayParameters';
import { Point2D } from '../../types/Point2D';
import { SlicePosition } from '../../types/SlicePosition';
import PositionInfo from '../PositionInfo/PositionInfo';
import SiteControls from '../SiteControls/SiteControls';
import styles from "./StimulationInfo.module.css";
import RegionLabel3D from '../RegionLabel3D/RegionLabel3D';
import { getOverlayTypeFromBoundary } from '../../utils/lookup';

type Props = {
  slicePosition: SlicePosition|null;
  selectedPoint: Point2D|null;
  boundary: BoundaryType;
  onSetSlicePosition: (position: SlicePosition) => void;
  onSetBoundary: (boundaryType: BoundaryType) => void;
}

function StimulationInfo({slicePosition, selectedPoint, boundary, onSetSlicePosition, onSetBoundary}: Props) {
  const lookupTables = React.useContext(LookupTablesContext);

  const overlayType: OverlayType = getOverlayTypeFromBoundary(boundary);
  return (
    <div className={styles.wrapper}>
      <h3>Dataset-Subject</h3>
      <div className={styles.topRow}>
          <div className={styles.subjectJ}>{lookupTables.templateName}-J</div>
          <div className={styles.subjectN}>{lookupTables.templateName}-N</div>
          <div className={styles.noConnections}>
            <div className={styles.noConnectionsDots}>
              <div className={styles.subjectJDisabled}></div>
              <div className={styles.subjectNDisabled}></div>
            </div>
            <div>
            Not connected to retrograde selected coordinate
            </div>
          </div>
      </div>
      <div className={styles.bottomRow}>
        <div className={styles.bottomRowLeft}>
          <h3>Selected Coordinates</h3>
          {slicePosition && ( 
            <RegionLabel3D  slicePosition={slicePosition} overlayType={overlayType} />
          )}
          <PositionInfo slicePosition={slicePosition} onSetSlicePosition={onSetSlicePosition} />
          </div>
        <div className={styles.bottomRowRight}>
          <SiteControls boundary={boundary} onSetBoundary={onSetBoundary} />
        </div>
      </div>
    </div>
  )
}

export default StimulationInfo