export class Pixel {
  r: number = 0;
  g: number = 0;
  b: number = 0;
}

export class ColorMapEntry {
  pixel: Pixel = new Pixel();
  label?: string;
  longLabel?: string;

  constructor (pixel: Pixel, label?: string, longLabel?: string) {
    this.pixel = pixel;
    this.label = label;
    this.longLabel = longLabel;
  }
}

export type ColorMap = Map<number, ColorMapEntry>;
