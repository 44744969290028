import React, { ChangeEvent } from "react";
import { SliceType, SLICE_TYPES } from "../../types/SliceType";
import styles from "./BrainSlice.module.css";
import up from "../../assets/images/up.svg";
import down from "../../assets/images/down.svg";
import upHover from "../../assets/images/upHover.svg";
import downHover from "../../assets/images/downHover.svg";
import { Point2D } from "../../types/Point2D";
import { SlicePosition } from "../../types/SlicePosition";
import { blend, isHighPixelValue, isLowPixelValue } from "../../utils/utils";
import { InjectionSite } from "../../types/InjectionSite";
import CombinerCanvas, { InputImages } from "../CombinerCanvas/CombinerCanvas";
import LookupTablesContext from "../../contexts/LookupTablesContext";
import { OverlayParameters } from "../../types/OverlayParameters";
import { get3DParcellationImagePath, get3DAtlasParcellationImagePath, getParcellationColorMap, isCharmSarmParcellation } from "../../utils/lookup";
import { ColorMap } from "../../types/ColorMap";

type Props = {
  type: SliceType;
  slicePosition: SlicePosition;
  retrogradeSlicePosition?: SlicePosition;
  onSetSlicePosition: (s: SlicePosition) => void;
  onSetRetrogradeSlicePosition?: (s: SlicePosition) => void;
  injectionSites?: InjectionSite[];
  overlayParameters?: OverlayParameters;
};

function BrainSlice({
  type,
  slicePosition,
  retrogradeSlicePosition,
  onSetSlicePosition,
  onSetRetrogradeSlicePosition,
  injectionSites,
  overlayParameters,
}: Props) {
  // Extract the slice number for this type of slice from the full slicePosition
  const sliceNumber = slicePosition[type];
  const [displaySliceNumber, setDisplaySliceNumber] = React.useState<string>(sliceNumber.toString());
  const [oldSliceNumber, setOldSliceNumber] = React.useState<number>(sliceNumber);
  const imageWrapperRef = React.useRef<HTMLDivElement>(null);
  const lookupTables = React.useContext(LookupTablesContext);

  // Check to see if the slice number was changed on us externally, and reset the displaySliceNumber if so
  if (oldSliceNumber !== sliceNumber) {
    setOldSliceNumber(sliceNumber);
    setDisplaySliceNumber(sliceNumber.toString());
  }

  const handleSliceChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDisplaySliceNumber(event.target.value);
    if (event.target.value) {
      const n = Number.parseInt(event.target.value);
      attemptSliceNumberChange(n);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    let newSliceNumber = sliceNumber;
    switch (event.code) {
      case "ArrowUp":
        newSliceNumber += event.shiftKey ? 10 : 1;
        attemptSliceNumberChange(newSliceNumber);
        break;
      case "PageUp":
        newSliceNumber += 10;
        attemptSliceNumberChange(newSliceNumber);
        break;
      case "ArrowDown":
        newSliceNumber -= event.shiftKey ? 10 : 1;
        attemptSliceNumberChange(newSliceNumber);
        break;
      case "PageDown":
        newSliceNumber -= 10;
        attemptSliceNumberChange(newSliceNumber);
        break;
    }
  };

  const handleIncrement = () => {
    attemptSliceNumberChange(sliceNumber + 1);
  };

  const handleDecrement = () => {
    attemptSliceNumberChange(sliceNumber - 1);
  };

  const isValidSliceNumber = (newSliceNumber: number) => {
    return !Number.isNaN(newSliceNumber) && newSliceNumber > 0 && newSliceNumber <= lookupTables.sliceCountMap.get(type)!;
  };

  const attemptSliceNumberChange = (newSliceNumber: number) => {
    if (isValidSliceNumber(newSliceNumber)) {
      setDisplaySliceNumber(newSliceNumber.toString());
      onSetSlicePosition({ ...slicePosition, [type]: newSliceNumber });
    }
  };

  const isValid = () => {
    return sliceNumber.toString() === displaySliceNumber;
  };

  /// +++++ GENERATING IMAGES

  const IMAGE_WIDTH = 240;

  // Get the native width of the 3d slice images. Required for calculating click positions after scaling
  const getNativeWidth = () => {
    return lookupTables.imageDimensions3D.get(type)!.width;
  };

  const getNativeHeight = () => {
    return lookupTables.imageDimensions3D.get(type)!.height;
  };

  // TODO: Make updates here to account for scaling of these images if necessary. Right now we
  // are assuming that they are 250px wide all the time. If we are going to get the current
  // dimensions from the boundingrect of the enclosing div, we need to make sure that's sized
  // properly the FIRST time we call it, otherwise the slice will not render
  const getCurrentWidth = () => {
    return IMAGE_WIDTH;
  };

  // Get the list of image URLs that will be used to compose the slice image
  const getUrls = () => {
    let typeBase = "";
    const paddedIndex = String(sliceNumber).padStart(3, "0");
    switch (type) {
      case "coronal":
        typeBase = `View-1_Coronal.InView_XY-3D_JK/3D_I-${paddedIndex}.png`;
        break;
      case "axial":
        typeBase = `View-2_Axial.InView_XY-3D_IK/3D_J-${paddedIndex}.png`;
        break;
      case "sagittal":
        typeBase = `View-3_Sagittal.InView_XY-3D_JI/3D_K-${paddedIndex}.png`;
        break;
    }

    // The first image is the background picture.
    const urls = [`${lookupTables.pathImagesSite3d}/3D-Background/${typeBase}`];

    // If looking at a specific injection site, we add an overlay
    if (injectionSites && injectionSites.length > 0 && overlayParameters?.overlayType) {
      if (overlayParameters.overlayType === "connections") {
        const overlays = injectionSites.map(
          // Using "masked" data for now. Update this to use "unmasked" when we have it
          (s) => `${lookupTables.pathImagesSite3d}/3D-Connection.maskS-1.maskC-1/SiteID-${s.getShortName()}/${typeBase}`
        );
        urls.push(...overlays);
      } else {
        // If this is a charm/sarm parcellation, there are two images that we need to pass to the combining function:
        // 1. The image that contains the parcellation data
        // 2. The image that says which lookup table to use (127 = CHARM, 255 = SARM) for CHARM/SARM parcellations (the "atlas")
        const pathSegment = get3DParcellationImagePath(overlayParameters?.overlayType);
        urls.push(`${lookupTables.pathImagesSite3d}/${pathSegment}/${typeBase}`);
        if (isCharmSarmParcellation(overlayParameters.overlayType)) {
          const atlasPathSegment = get3DAtlasParcellationImagePath();
          urls.push(`${lookupTables.pathImagesSite3d}/${atlasPathSegment}/${typeBase}`);
        }
      }
    }
    return urls;
  };

  // We are creating a closure that uses context from the enclosing function when executing
  // It will be called by CombineCanvas.
  const combineBackgroundImageWithOverlay = (contexts: CanvasRenderingContext2D[]): HTMLCanvasElement | undefined => {
    const image1 = contexts[0].getImageData(0, 0, getNativeWidth(), getNativeHeight());
    const colorImages = contexts
      .slice(1)
      .map((context) => context.getImageData(0, 0, getNativeWidth(), getNativeHeight()));

    // Setup the result canvas
    const resultCanvas = document.createElement("canvas");
    resultCanvas.width = getNativeWidth();
    resultCanvas.height = getNativeHeight();
    const resultContext = resultCanvas.getContext("2d");
    if (!resultContext) {
      console.error("Could not create blended image");
      return;
    }
    const resultImage = resultContext.getImageData(0, 0, getNativeWidth(), getNativeHeight());

    // If there is only the background image provided, we just return it with no overlay
    if (contexts.length === 1) {
      resultContext.putImageData(image1, 0, 0);
      return resultCanvas;
    }

    // Otherwise, we have a background image onto which we need to show one or more overlays
    const backgroundData = image1.data;
    const resultData = resultImage.data;
    const colorMap =
        (!overlayParameters || overlayParameters.overlayType === "connections")
          ? lookupTables.connectionsColorMap
          : getParcellationColorMap(lookupTables, overlayParameters.overlayType, 1);

    // If this is a charm/sarm parcellation, we need to check the atlas image to see which lookup table to use
    let atlasImage: ImageData | undefined;
    let alternateColorMap: ColorMap | undefined;
    if (overlayParameters && isCharmSarmParcellation(overlayParameters.overlayType)) {
      atlasImage = colorImages.pop()
      alternateColorMap = getParcellationColorMap(lookupTables, overlayParameters.overlayType, 2);
    }

    for (var i = 0; i < backgroundData.length; i += 4) {
      let r = backgroundData[i];
      let g = backgroundData[i + 1];
      let b = backgroundData[i + 2];
      if (colorMap && overlayParameters) {
        // Check to see if there is data in the color data image for this pixel. We can check by looking
        // only at the "r" pixel, because the color data is grayscale and the r/g/b numbers all have the
        // same value
        let highOverlayValue = 0;
        for (const colorImage of colorImages) {
          const colorData = colorImage.data;
          if (colorData[i] > highOverlayValue) {
            highOverlayValue = colorData[i];
          }
        }

        // Give the highest pixel value from the overlay, lookup the corresponding color and blend it
        // with the background
        if (!isLowPixelValue(highOverlayValue)) {
          let colorMapForThisPixel = colorMap;
          // If this is a charm/sarm parcellation, we need to check the atlas image to see which lookup table to use
          if (atlasImage && alternateColorMap && isHighPixelValue(atlasImage.data[i])) {
            colorMapForThisPixel = alternateColorMap;
          }
          const pixel = colorMapForThisPixel.get(highOverlayValue)?.pixel;
          if (pixel) {
            r = blend(r, pixel.r, overlayParameters.opacity);
            g = blend(g, pixel.g, overlayParameters.opacity);
            b = blend(b, pixel.b, overlayParameters.opacity);
          }
        }
      }
      resultData[i] = r;
      resultData[i + 1] = g;
      resultData[i + 2] = b;
      resultData[i + 3] = backgroundData[i + 3];
    }

    resultContext.putImageData(resultImage, 0, 0);
    return resultCanvas;
  };

  // TODO: Update all closures to use the dimensions passed in here, rather than also gettingn them
  // separately from within the combine() closure
  const inputImages: InputImages = {
    width: getNativeWidth(),
    height: getNativeHeight(),
    urls: getUrls(),
    combine: combineBackgroundImageWithOverlay,
  };

  /// +++++ FUNCTIONALITY RELATED TO SCALING IMAGES AND HANDLING CLICKS

  const getScalingFromLogicalSizeToScreenSize = (ref: HTMLDivElement) => {
    return getCurrentWidth() / getNativeWidth();
  };

  const getScreenPointFromLogicalPoint = (point: Point2D, ref: HTMLDivElement) => {
    return {
      x: Math.round(point.x * getScalingFromLogicalSizeToScreenSize(ref)),
      y: Math.round(point.y * getScalingFromLogicalSizeToScreenSize(ref)),
    };
  };

  const getLogicalPointFromScreenPoint = (point: Point2D, ref: HTMLDivElement) => {
    return {
      x: Math.round(point.x * (1 / getScalingFromLogicalSizeToScreenSize(ref))),
      y: Math.round(point.y * (1 / getScalingFromLogicalSizeToScreenSize(ref))),
    };
  };

  const slicePositionIsOnImage = (_slicePosition: SlicePosition | undefined) => {
    return _slicePosition && (_slicePosition[type] === sliceNumber)
  }
  
  const getLogicalPointFromSlicePosition = (slicePosition: SlicePosition, type: SliceType) => {
    switch (type) {
      case "axial":
        return { x: slicePosition.sagittal, y: slicePosition.coronal };
      case "coronal":
        return { x: slicePosition.sagittal, y: slicePosition.axial };
      case "sagittal":
        return { x: slicePosition.coronal, y: slicePosition.axial };
    }
  };

  const getSlicePositionFromLogicalPoint = (point: Point2D, type: SliceType) => {
    let result: SlicePosition;

    switch (type) {
      case "axial":
        result = { axial: sliceNumber, sagittal: point.x, coronal: point.y };
        break;
      case "coronal":
        result = { axial: point.y, sagittal: point.x, coronal: sliceNumber };
        break;
      case "sagittal":
        result = { axial: point.y, sagittal: sliceNumber, coronal: point.x };
        break;
    }

    // Make sure that the new position doesn't go outside the boundaries of allowed
    // values for the SlicePosition
    SLICE_TYPES.forEach((t) => {
      if (result[t] <= 0) {
        result[t] = 1;
      }
      const max = lookupTables.sliceCountMap.get(t);
      if (max && result[t] > max) {
        result[t] = max;
      }
    });
    return result;
  };

  const handleImageClick = (point: Point2D, shiftKey: boolean = false) => {
    if (imageWrapperRef.current) {
      if (point) {
        const logicalPoint = getLogicalPointFromScreenPoint(point, imageWrapperRef.current);
        const slicePosition = getSlicePositionFromLogicalPoint(logicalPoint, type);
        if (shiftKey) {
          onSetRetrogradeSlicePosition && onSetRetrogradeSlicePosition(slicePosition);
        } else {
          onSetSlicePosition(slicePosition);
        }
      }
    }
  };

  // Styles for the marker that need to be dynamically generated to place it in the
  // correct location
  const getMarkerPositionStyles = (_slicePosition: SlicePosition | undefined, markerStyle: 'cross' | 'circle') => {
    const markerSize = markerStyle === "cross" ? 15 : 10;
    // const fudgeFactor = markerStyle === "cross" ? 3 : 8;
    if (_slicePosition && imageWrapperRef.current) {
      const logicalPoint = getLogicalPointFromSlicePosition(_slicePosition, type);
      if (logicalPoint) {
        const scaledPoint = getScreenPointFromLogicalPoint(logicalPoint, imageWrapperRef.current);
        if (scaledPoint) {
          return {
            top: scaledPoint.y - (markerStyle === "cross" ? 9.5 : 8.5),
            left: scaledPoint.x - (markerStyle === "cross" ? 9.5 : 8.75),
            width: markerSize,
            height: markerSize,
          };
        }
      }
    }
  };

  /// +++++ RENDERING

  return (
    <div className={styles.wrapper} data-testid={"slice-type-" + type}>
      <div
        className={styles.imageWrapper}
        ref={imageWrapperRef}
        style={{ height: getNativeHeight() * (IMAGE_WIDTH / getNativeWidth()) }}
      >
        <CombinerCanvas inputImages={inputImages} onSetClickedPoint={handleImageClick}/>
        {type === "axial" && <div className={styles.left}>L</div>}
        {type === "axial" && <div className={styles.right}>R</div>}
        <div
          className={`${styles.marker} ${styles.cross}`}
          style={getMarkerPositionStyles(slicePosition, 'cross')}
        ></div>
        {slicePositionIsOnImage(retrogradeSlicePosition) &&
          <div
            className={`${styles.marker} ${styles.circle}`}
            style={getMarkerPositionStyles(retrogradeSlicePosition, 'circle')}
          ></div>
}
      </div>
      <div className={styles.controls}>
        <span className={styles.label}>{type}</span>
        <div className={`${styles.inputWrapper} ${isValid() ? styles.valid : styles.invalid}`}>
          <div className={styles.controlWrapper}>
            <img src={down} alt="down" className={styles.arrow} onClick={handleDecrement} draggable="false" />
            <img src={downHover} alt="down" className={styles.arrowHover} onClick={handleDecrement} draggable="false" />
          </div>
          <input
            className={styles.input}
            onChange={handleSliceChange}
            onKeyDown={handleKeyDown}
            value={displaySliceNumber}
          />
          <div className={styles.controlWrapper}>
            <img src={up} alt="up" className={styles.arrow} onClick={handleIncrement} draggable="false" />
            <img src={upHover} alt="up" className={styles.arrowHover} onClick={handleIncrement} draggable="false" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BrainSlice;
