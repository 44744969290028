import { Outlet, useLocation } from "react-router-dom";
import styles from "./App.module.css";
import Header from "./components/Header/Header";
import React, { useEffect } from "react";
import AppNameContext from "./contexts/AppNameContext";
import { HelmetProvider, Helmet } from "react-helmet-async";
import DeviceWarning from "./components/DeviceWarning/DeviceWarning";
import TagManager from "react-gtm-module";
import { GTM_ID } from "./constants/constants";

function App() {
  const tagManagerArgs = {
    gtmId: GTM_ID
  }
  TagManager.initialize(tagManagerArgs);

  const location = useLocation();

  useEffect(() => {
    (window as any).dataLayer.push({
      event: 'pageview',
      page: {
        url: location,
      }    
    });
    }, [location]);

  return (
    <div className={styles.App}>
      <HelmetProvider>
        <Helmet>
          <title>{React.useContext(AppNameContext)}</title>
        </Helmet>
        <div className={styles.headerWrapper}>
          <Header />
        </div>
        <div className={styles.outletWrapper}>
          <Outlet />
        </div>
        <DeviceWarning />
      </HelmetProvider>
    </div>
  );
}

export default App;
