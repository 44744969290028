import React from "react";
import LookupTablesContext from "../../contexts/LookupTablesContext";
import { InjectionSite } from "../../types/InjectionSite";
import { OverlayParameters } from "../../types/OverlayParameters";
import { Point2D } from "../../types/Point2D";
import Connection2DCanvas from "../Connection2DCanvas/Connection2DCanvas";
import ConnectionRegionLabel from "../ConnectionRegionLabel/ConnectionRegionLabel";
import Marker from "../Marker/Marker";
import PointMarker from "../PointMarker/PointMarker";
import styles from "./ConnectionMap.module.css";
import circle from "../../assets/images/circle.svg";

type Props = {
  injectionSites?: InjectionSite[];
  selectedPoint: Point2D | null;
  retrogradeSelectedPoint: Point2D | null;
  onSetSelectedPoint: (point: Point2D) => void;
  onSetRetrogradeSelectionPoint: (point: Point2D | null) => void;
  overlayParameters: OverlayParameters;
};

function ConnectionMap({
  injectionSites,
  selectedPoint,
  retrogradeSelectedPoint,
  onSetSelectedPoint,
  onSetRetrogradeSelectionPoint,
  overlayParameters,
}: Props) {
  const [mouseOverActiveLocation, setMouseOverActiveLocation] = React.useState<Boolean>();
  const [mouseLocationPoint, setMouseLocationPoint] = React.useState<Point2D>();
  const lookupTables = React.useContext(LookupTablesContext);

  // When the user clicks on the image, place the marker and notify the parent component
  const handleClick = (point: Point2D, shiftKey: boolean = false) => {
    const lookupResult = lookupTables.connections2Dto3DMap?.get(point);
    if (lookupResult) {
      if (shiftKey) {
        onSetRetrogradeSelectionPoint(point);
      } else {
        onSetSelectedPoint(point);
      }
    }
  };

  // Update the mouse cursor based on whether or not they are a over a location that maps
  // to a valid point in the 3d image
  const handleMouseMove = (point: Point2D) => {
    if (point) {
      setMouseOverActiveLocation(lookupTables.connections2Dto3DMap?.get(point) !== undefined);
      setMouseLocationPoint(point);
    }
  };

  const handleClearSelectionClick = () => {
    onSetRetrogradeSelectionPoint(null);
  };

  const getInjectionPoint = () => {
    if (injectionSites?.length && injectionSites[0].location) {
      const location3d = lookupTables.stimulationSites2Dto3DMap?.get(injectionSites[0]?.location);
      if (location3d) {
        const location2d = lookupTables.connections3Dto2DMap?.get(location3d);
        return location2d;
      }
    }
  };

  const injectionSitePoint = getInjectionPoint();
  // Set default position for selection point if not set
  React.useEffect(() => {
    if (injectionSitePoint) {
      handleClick(injectionSitePoint);
    }
  }, []); // eslint-disable-line

  return (
    <div className={styles.wrapper} style={{ cursor: mouseOverActiveLocation ? "crosshair" : "not-allowed" }}>
      <div className={styles.imageWrapper}>
        <img className={styles.borderImage}
             src={`${lookupTables.pathImagesConnection2d}/Conn2D-Border.png`} 
             style={{
              left: lookupTables.connectionsBorderLeftOffset,
              top: lookupTables.connectionsBorderTopOffset,
            }}
                 alt="" />
        {injectionSites && injectionSites?.length && (
          <Connection2DCanvas
            onSetClickedPoint={handleClick}
            onSetMousePosition={handleMouseMove}
            injectionSites={injectionSites}
            overlayParameters={overlayParameters}
          />
        )}
        {selectedPoint && <Marker point={selectedPoint} />}
        {retrogradeSelectedPoint && !(retrogradeSelectedPoint.x === 0 && retrogradeSelectedPoint.y === 0 ) && <Marker point={retrogradeSelectedPoint} markerSize={10} markerStyle="circle" />}
        {injectionSites && injectionSites?.length && (
          <PointMarker point={injectionSitePoint} displayStyle={injectionSites[0].subject.toLowerCase()} />
        )}
        <div className={styles.tools}>
          <div className={styles.toolsHelp}>
            <img src={circle} alt="" />
            <div>Shift-Click for retrograde selection</div>
          </div>
          <div className={styles.clearSelection} onClick={handleClearSelectionClick}>          
            <div>{retrogradeSelectedPoint && <span>Clear Retrograde Selection</span>}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.infoWrapper}>
        {mouseLocationPoint && <ConnectionRegionLabel point={mouseLocationPoint} overlayParameters={overlayParameters}/>}
        <div className={styles.injectionSiteWrapper}>
          {injectionSites && injectionSites.length > 0 && (
            <div className={`${styles.injectionSiteCaption} ${styles[injectionSites[0].subject.toLowerCase()]}`}>
              <label>Selected site(s)</label>
              <div>{injectionSites.map((s) => s.getShortName()).join(", ")}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ConnectionMap;
