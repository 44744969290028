import React from "react";
import { Helmet } from "react-helmet-async";
import Connections from "../../components/Connections/Connections";
import ConnectionsHelp from "../../components/ConnectionsHelp/ConnectionsHelp";
import StimulationSites from "../../components/StimulationSites/StimulationSites";
import AppNameContext from "../../contexts/AppNameContext";
import LookupTablesContext, { LookupTables } from "../../contexts/LookupTablesContext";
import { InjectionSite } from "../../types/InjectionSite";
import { SliceType } from "../../types/SliceType";
import { initializeLookupData } from "../../utils/lookup";
import styles from "./SitesNMT.module.css";

function SitesNMT() {
  const [injectionSites, setInjectionSites] = React.useState<InjectionSite[]>([]);
  const [activeSites, setActiveSites] = React.useState<string[] | null>(null);
  const [lookupTables, setLookupTables] = React.useState<LookupTables>(new LookupTables());

  React.useEffect(() => {
    initializeLookupData(setLookupTables, "Template-NMT20sym", [
      "d99_12a_parcellation",
      "charm_lv1_parcellation",
      "charm_lv2_parcellation",
      "charm_lv3_parcellation",
      "charm_lv4_parcellation",
      "charm_lv5_parcellation",
      "charm_lv6_parcellation",
      "sarm_lv1_parcellation",
      "sarm_lv2_parcellation",
      "sarm_lv3_parcellation",
      "sarm_lv4_parcellation",
      "sarm_lv5_parcellation",
      "sarm_lv6_parcellation",
    ]);
    setLookupTables((previousTable) => {
      return {
        ...previousTable,
        availableStimulationSitesBoundaries: [
          "d99_12a",
          "charm_lv1",
          "charm_lv2",
          "charm_lv3",
          "charm_lv4",
          "charm_lv5",
          "charm_lv6",
          "sulcigyri",
        ],
        templateName: "NMT20sym",
        ktoRTransform: (k: number) => {
          return -31.875 + 0.5 * k;
        },
        itoATransform: (i: number) => {
          return 49.75 - 0.5 * i;
        },
        jtoSTransform: (j: number) => {
          return 42.0 - 0.5 * j;
        },
        rtoKTransform: (r: number) => {
          return Math.round(2 * (r + 31.875));
        },
        atoITransform: (a: number) => {
          return Math.round(2 * (49.75 - a));
        },
        stoJTransform: (s: number) => {
          return Math.round(2 * (42.0 - s));
        },
        siteBorderLeftOffset: -6,
        siteBorderTopOffset: -14,
        connectionsBorderLeftOffset: -30,
        connectionsBorderTopOffset: -26,
        sliceCountMap: new Map<SliceType, number>([
          ["axial", 100],
          ["coronal", 154],
          ["sagittal", 126],
        ]),
        imageDimensions3D: new Map<SliceType, { width: number; height: number }>([
          ["axial", { width: 126, height: 154 }],
          ["coronal", { width: 126, height: 100 }],
          ["sagittal", { width: 154, height: 100 }],
        ]),
      };
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>{React.useContext(AppNameContext)} - Stimulation Sites</title>
      </Helmet>
      <div className={styles.wrapper}>
        <LookupTablesContext.Provider value={lookupTables}>
          {lookupTables.connections2Dto3DMap &&
            lookupTables.parcellationColorMaps &&
            lookupTables.connections2Dto3DMap &&
            lookupTables.connections3Dto2DMap &&
            lookupTables.stimulationSites2Dto3DMap &&
            lookupTables.stimulationSites3Dto2DMap &&
            lookupTables.injectionSites &&
            (
              <>
                <div>
                  <StimulationSites onSetInjectionSites={setInjectionSites} activeSites={activeSites} />
                </div>
                <div>
                  {!injectionSites.length && <ConnectionsHelp />}
                  {injectionSites.length > 0 && (
                    <Connections injectionSites={injectionSites} onSetActiveSites={setActiveSites} />
                  )}
                </div>
              </>
            )}
        </LookupTablesContext.Provider>
      </div>
    </div>
  );
}

export default SitesNMT;
