import React from "react";
import styles from "./InjectionSiteMarker.module.css";
import { InjectionSite } from "../../types/InjectionSite";
//eslint-disable-next-line
import Connection2DCanvas from "../Connection2DCanvas/Connection2DCanvas";

type Props = {
  injectionSite: InjectionSite;
  onSetInjectionSite: (site: InjectionSite, multiSelect: boolean ) => void;
  onSetImageData?: (data: string) => void;
  isSelected?: boolean;
  isBackgrounded?: boolean;
};

function InjectionSiteMarker({ injectionSite, onSetInjectionSite, onSetImageData, isSelected, isBackgrounded }: Props) {
  // This value is also in the css file
  const MARKER_SIZE = 8;

  const getDynamicStyles = (site: InjectionSite) => {
    return {
      left: site.location.x - MARKER_SIZE / 2,
      top: site.location.y - MARKER_SIZE / 2,
    };
  };

  const handleMarkerClick: React.MouseEventHandler<HTMLDivElement> = (event): void => {
    onSetInjectionSite(injectionSite, event.shiftKey);
  };

  return (
    <div
      className={`
        ${styles.wrapper} 
        ${isSelected ? styles.selected : ""} 
        ${isBackgrounded ? styles.backgrounded : ""} 
        ${styles[injectionSite.subject.toLowerCase()]}
      `}
      style={getDynamicStyles(injectionSite)}
      onClick={handleMarkerClick}
    >
      <div className={styles.hover}>
        <div className={styles.canvasWrapper}>
          <img src={injectionSite.getColoredImage()} alt="" />
        </div>
        <div className={styles.name}>{injectionSite.getShortName()}</div>
      </div>
    </div>
  );
}

export default InjectionSiteMarker;
