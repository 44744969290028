export class PointMap<K, V> implements Map<K, V> {
  private readonly _innerMap: Map<string, V>;

  constructor() {
    this._innerMap = new Map<string, V>();
  }

  private toString(key: K): string {
    return JSON.stringify(key);
  }

  clear(): void {
    return this._innerMap.clear();
  }

  delete(key: K): boolean {
    return this._innerMap.delete(this.toString(key));
  }

  forEach(callbackfn: (value: V, key: K, map: Map<K, V>) => void, thisArg?: any): void {
    throw new Error("Method not implemented.");
  }

  get(key: K): V | undefined {
    return this._innerMap.get(this.toString(key));
  }

  has(key: K): boolean {
    return this._innerMap.has(this.toString(key));
  }

  set(key: K, value: V): this {
    this._innerMap.set(this.toString(key), value);
    return this;
  }
  
  // TODO: This is bad. But getting it to be correct seems like a pain
  size: number = 0;
  
  entries(): IterableIterator<[K, V]> {
    throw new Error("Method not implemented.");
  }

  keys(): IterableIterator<K> {
    throw new Error("Method not implemented.");
  }

  values(): IterableIterator<V> {
    throw new Error("Method not implemented.");
  }

  [Symbol.iterator](): IterableIterator<[K, V]> {
    throw new Error("Method not implemented.");
  }

  [Symbol.toStringTag]: string = "PointMap";
}
