import React from "react";
import { SITE_2D_HEIGHT, SITE_2D_WIDTH } from "../../constants/constants";
import LookupTablesContext from "../../contexts/LookupTablesContext";
import { BoundaryType } from "../../types/OverlayParameters";
import { Point2D } from "../../types/Point2D";
import { blend } from "../../utils/utils";
import CombinerCanvas, { InputImages } from "../CombinerCanvas/CombinerCanvas";
import styles from "./SiteBoundaryCanvas.module.css";

type Props = {
  boundary: BoundaryType
  onSetClickedPoint: (point: Point2D) => void;
  onSetMousePosition: (point: Point2D) => void;
};

// Display the Boundaries overlaid on the Background or the 2D version of the
// Stimulation sites map
function SiteBoundaryCanvas({ boundary, onSetClickedPoint, onSetMousePosition }: Props) {
  const lookupTables = React.useContext(LookupTablesContext);

  const CANVAS_WIDTH = SITE_2D_WIDTH;
  const CANVAS_HEIGHT = SITE_2D_HEIGHT;
 
  // This is the function that blends the background and boundary images 
  const combineBackgroundAndBoundaries = (contexts: CanvasRenderingContext2D[]) => {
    const image1 = contexts[0].getImageData(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
    const image2 = contexts[1].getImageData(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

    const resultCanvas = document.createElement("canvas");
    resultCanvas.width = CANVAS_WIDTH;
    resultCanvas.height = CANVAS_HEIGHT;
    const resultContext = resultCanvas.getContext("2d");
    if (!resultContext) {
      console.error("Could not create blended image");
      return;
    }
    const resultImage = resultContext.getImageData(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT)

    // get the pixel data as array
    const image1Data = image1.data;
    const image2Data = image2.data;
    const resultData = resultImage.data;

    // Blend the background and the boundaries together
    for (var i = 0; i < image2Data.length; i += 4) {
      resultData[i] = blend(image1Data[i],image2Data[i],image2Data[i+3]/255);
      resultData[i+1] = blend(image1Data[i+1],image2Data[i+1],image2Data[i+3]/255);
      resultData[i+2] = blend(image1Data[i+2],image2Data[i+2],image2Data[i+3]/255);
      resultData[i+3] = 255 - ((255 - image1Data[i+3]) * (255 - image2Data[i+3]) / 255)
    }

    // Draw it on the canvas, and return the canvas
    resultContext.putImageData(resultImage, 0, 0);
    return resultCanvas;
  };

  const inputImages: InputImages = {
    width: CANVAS_WIDTH,
    height: CANVAS_HEIGHT,
    urls: [
      `${lookupTables.pathImagesSite2d}/Site2D-Background.png`,
      // `${lookupTables.pathImagesSite2d}/Site2D-Boundary-CBCetal15.png`
    ],
    combine: combineBackgroundAndBoundaries
  }

  // Add the boundary image to the inputImages
  let boundaryImage:string;
  switch (boundary) {
    case "d99_12a":
      boundaryImage = "Site2D-Boundary-D99_12a.png";
      break;
    case "charm_lv1":
      boundaryImage = "Site2D-Boundary-CHARM_lv1.png";
      break;
    case "charm_lv2":
      boundaryImage = "Site2D-Boundary-CHARM_lv2.png";
      break;
    case "charm_lv3":
      boundaryImage = "Site2D-Boundary-CHARM_lv3.png";
      break;
    case "charm_lv4":
      boundaryImage = "Site2D-Boundary-CHARM_lv4.png";
      break;
    case "charm_lv5":
      boundaryImage = "Site2D-Boundary-CHARM_lv5.png";
      break;
    case "charm_lv6":
      boundaryImage = "Site2D-Boundary-CHARM_lv6.png";
      break;
    case "atlas":
    default:
      boundaryImage = "Site2D-Boundary-CBCetal15.png";
      break;
  }
  inputImages.urls.push(`${lookupTables.pathImagesSite2d}/${boundaryImage}`);

  return (
    <div className={styles.wrapper}>
      <CombinerCanvas
        inputImages={inputImages}
        onSetClickedPoint={onSetClickedPoint}
        onSetMousePosition={onSetMousePosition}
      />
    </div>
  );
}

export default SiteBoundaryCanvas;

// Reference : https://truongtx.me/2014/12/28/image-blending-with-html-canvas
