import { Point3D } from "./Point3D";

export class SlicePosition {
  sagittal: number = 0;
  coronal: number = 0;
  axial: number = 0;

  constructor(point?: Point3D) {
    if (point != null) {
      this.coronal = point.i;
      this.sagittal = point.k;
      this.axial = point.j;
    }
  }
}
