import React from "react";
import styles from "./Marker.module.css";
import { Point2D } from "../../types/Point2D";
type Props = {
  point?: Point2D;
  markerSize?: number;
  markerStyle?: "cross" | "circle";
};

function Marker({ point, markerSize = 35, markerStyle = "cross" }: Props) {
  // Styles for the marker that need to be dynamically generated to place it in the
  // correct location
  const getMarkerPositionStyles = () => {
    let result = {};
    if (point) {
      const fudgeFactor = markerStyle === "cross" ? 3 : 6; // set in CSS
      result = {
        top: point.y - (markerSize + fudgeFactor) / 2, // TOOD: Fix this. Not sure why needed
        left: point.x - (markerSize + fudgeFactor) / 2,
        width: markerSize,
        height: markerSize,
      };
    }
    return result;
  };

  return (
    <>
      <div
        data-testid="cross"
        className={`${styles.marker} ${styles[markerStyle]}`}
        style={getMarkerPositionStyles()}
      ></div>
    </>
  );
}

export default Marker;
