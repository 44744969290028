import { useRouteError } from "react-router-dom";
import Header from "../../components/Header/Header";
import styles from "./ErrorPage.module.css";
import React from "react";

function ErrorPage() {
  const error: any = useRouteError();

  return (
    <div className={styles.wrapper}>
      <Header />
      <h1>Error</h1>
      <p>An unexpected error has occurred</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}

export default ErrorPage;
