import React from "react";
import { Point2D } from "../../types/Point2D";
import styles from "./PointMarker.module.css";

type Props = {
  point?: Point2D;
  displayStyle: string;
};

function PointMarker({ point, displayStyle }: Props) {
  // Styles for the "point" marker that need to be dynamically generated to place it in the
  // correct location
  const getMarkerPositionStyles = () => {
    const markerSize = 10;

    if (point) {
      const lineWidth = 3; // set in CSS
      return {
        top: point.y - (markerSize + lineWidth) / 2 + 1, // TOOD: Fix this. Not sure why needed
        left: point.x - (markerSize + lineWidth) / 2 + 1,
        width: markerSize,
        height: markerSize,
        borderRadius: markerSize,
      };
    }
  };

  return (
    <>
      {point &&
        <div className={`${styles.point} ${styles[displayStyle]}`} style={getMarkerPositionStyles()}></div>
      }
    </>
  );
}

export default PointMarker;
