import React from "react";
import { BASE_PATH_DATA, BASE_PATH_PREGENERATED } from "../constants/constants";
import { LookupTables } from "../contexts/LookupTablesContext";
import { ColorMap, ColorMapEntry } from "../types/ColorMap";
import { InjectionSite } from "../types/InjectionSite";
import { BoundaryType, OverlayColorLookupType, OverlayVariant, OverlayType } from "../types/OverlayParameters";
import { Point2D } from "../types/Point2D";
import { Point3D } from "../types/Point3D";
import { PointMap } from "../types/PointMap";
import { TemplateType } from "../types/TemplateType";
import { fetchData } from "../utils/utils";

// Initialize all lookup data for a Template
export const initializeLookupData = async (
  setLookupTables: React.Dispatch<React.SetStateAction<LookupTables>>,
  templateType: TemplateType,
  parcellations: OverlayColorLookupType[]) => {
  const basePath = `${BASE_PATH_DATA}/${templateType}`;
  const lookupTablesBasePath = `${basePath}/LookupTables`;
  const pregneratedBasePath = `${BASE_PATH_PREGENERATED}/${templateType}`;

  setLookupTables((previousTable) => {
    return {
      ...previousTable,
      pathImagesSite2d: `${basePath}/Images-Site2D`,
      pathImagesSite3d: `${basePath}/Images-3D`,
      pathImagesConnection2d: `${basePath}/Images-Conn2D`,
      pathImagesPregenerated: pregneratedBasePath,
      availableConnectionsParcellations: parcellations,
    };
  });

  const connectionsColorMap = await fetchColorLUTConnectionData(lookupTablesBasePath);
  setLookupTables((previousTable) => {
    return { ...previousTable, connectionsColorMap };
  });

  const parcellationColorMaps = await fetchColorLUTParcellationData(lookupTablesBasePath, parcellations);
  setLookupTables((previousTable) => {
    return { ...previousTable, parcellationColorMaps };
  });

  const connections2Dto3DMap = await fetchConnections2Dto3DData(lookupTablesBasePath);
  setLookupTables((previousTable) => {
    return { ...previousTable, connections2Dto3DMap };
  });

  const connections3Dto2DMap = await fetchConnections3Dto2DData(lookupTablesBasePath);
  setLookupTables((previousTable) => {
    return { ...previousTable, connections3Dto2DMap };
  });

  const stimulationSites2Dto3DMap = await fetchStimulationSites2Dto3DData(lookupTablesBasePath);
  setLookupTables((previousTable) => {
    return { ...previousTable, stimulationSites2Dto3DMap };
  });

  const stimulationSites3Dto2DMap = await fetchStimulationSites3Dto2DData(lookupTablesBasePath);
  setLookupTables((previousTable) => {
    return { ...previousTable, stimulationSites3Dto2DMap };
  });

  const injectionSites = await fetchInjectionSitesData(lookupTablesBasePath, templateType);
  setLookupTables((previousTable) => {
    return { ...previousTable, injectionSites };
  });

};

// Load and parse the color lookup table. This maps an index (the grayscale color
// value from an image) to an actual RGB color
export const fetchColorLUTConnectionData = async (basePath: string): Promise<Map<number, ColorMapEntry>> => {
  const lines = await fetchData(`${basePath}/ColorLUT-Connection.csv`);
  const colorMap = new Map<number, ColorMapEntry>();
  lines.forEach((line) => {
    const lineItems = line.split(",").map((x) => parseInt(x));
    colorMap.set(lineItems[0], new ColorMapEntry({ r: lineItems[1], g: lineItems[2], b: lineItems[3] }));
  });
  return colorMap;
};

export const fetchColorLUTParcellationData = async (
  basePath: string,
  parcellations: OverlayColorLookupType[]
): Promise<Map<OverlayColorLookupType, Map<number, ColorMapEntry>>> => {
  const result = new Map<OverlayColorLookupType, Map<number, ColorMapEntry>>();
  parcellations.forEach(async (parcellation) => {
    let path = "";
    switch (parcellation) {
      case "atlas_parcellation":
        path = `${basePath}/ColorLabelLUT-Parcellation-CBCetal15.csv`;
        break;
      case "d99_12a_parcellation":
        path = `${basePath}/ColorLabelLUT-Parcellation-D99_12a.csv`;
        break;
      case "charm_lv1_parcellation":
        path = `${basePath}/ColorLabelLUT-Parcellation-CHARM_lv1.csv`;
        break;
      case "charm_lv2_parcellation":
        path = `${basePath}/ColorLabelLUT-Parcellation-CHARM_lv2.csv`;
        break;
      case "charm_lv3_parcellation":
        path = `${basePath}/ColorLabelLUT-Parcellation-CHARM_lv3.csv`;
        break;
      case "charm_lv4_parcellation":
        path = `${basePath}/ColorLabelLUT-Parcellation-CHARM_lv4.csv`;
        break;
      case "charm_lv5_parcellation":
        path = `${basePath}/ColorLabelLUT-Parcellation-CHARM_lv5.csv`;
        break;
      case "charm_lv6_parcellation":
        path = `${basePath}/ColorLabelLUT-Parcellation-CHARM_lv6.csv`;
        break;
      case "sarm_lv1_parcellation":
        path = `${basePath}/ColorLabelLUT-Parcellation-SARM_lv1.csv`;
        break;
      case "sarm_lv2_parcellation":
        path = `${basePath}/ColorLabelLUT-Parcellation-SARM_lv2.csv`;
        break;
      case "sarm_lv3_parcellation":
        path = `${basePath}/ColorLabelLUT-Parcellation-SARM_lv3.csv`;
        break;
      case "sarm_lv4_parcellation":
        path = `${basePath}/ColorLabelLUT-Parcellation-SARM_lv4.csv`;
        break;
      case "sarm_lv5_parcellation":
        path = `${basePath}/ColorLabelLUT-Parcellation-SARM_lv5.csv`;
        break;
      case "sarm_lv6_parcellation":
        path = `${basePath}/ColorLabelLUT-Parcellation-SARM_lv6.csv`;
        break;
    }
    const lines = await fetchData(path);
    const colorMap = new Map<number, ColorMapEntry>();
    const records = CSVToArray(lines.join("\n"), ",");
    records.forEach((record) => {
      const index = parseInt(record[0]);
      const r = parseInt(record[1]);
      const g = parseInt(record[2]);
      const b = parseInt(record[3]);
      const label = record[4];
      const longLabel = record[5];
      colorMap.set(index, new ColorMapEntry({ r, g, b }, label, longLabel));
    });
    result.set(parcellation, colorMap);
  });
  return result;
};

export const fetchConnections2Dto3DData = async (basePath: string): Promise<PointMap<Point2D, Point3D>> => {
  const lines = await fetchData(`${basePath}/LUT-Conn2D_to_3D.csv`);
  const mapping = new PointMap<Point2D, Point3D>();
  lines.forEach((line) => {
    const lineItems = line.split(",").map((x) => parseInt(x));
    mapping.set({ x: lineItems[0], y: lineItems[1] }, { i: lineItems[2], j: lineItems[3], k: lineItems[4] });
  });
  return mapping;
};

export const fetchConnections3Dto2DData = async (basePath: string): Promise<PointMap<Point3D, Point2D>> => {
  const lines = await fetchData(`${basePath}/LUT-3D_to_Conn2D.csv`);
  const mapping = new PointMap<Point3D, Point2D>();
  lines.forEach((line) => {
    const lineItems = line.split(",").map((x) => parseInt(x));
    mapping.set({ i: lineItems[0], j: lineItems[1], k: lineItems[2] }, { x: lineItems[3], y: lineItems[4] });
  });
  return mapping;
};

export const fetchStimulationSites2Dto3DData = async (basePath: string): Promise<PointMap<Point2D, Point3D>> => {
  const lines = await fetchData(`${basePath}/LUT-Site2D_to_3D.csv`);
  const mapping = new PointMap<Point2D, Point3D>();
  lines.forEach((line) => {
    const lineItems = line.split(",").map((x) => parseInt(x));
    mapping.set({ x: lineItems[0], y: lineItems[1] }, { i: lineItems[2], j: lineItems[3], k: lineItems[4] });
  });
  return mapping;
};

// Load and parse the 3D to 2D mapping data
export const fetchStimulationSites3Dto2DData = async (basePath: string): Promise<PointMap<Point3D, Point2D>> => {
  const lines = await fetchData(`${basePath}/LUT-3D_to_Site2D.csv`);
  const mapping = new PointMap<Point3D, Point2D>();
  lines.forEach((line) => {
    const lineItems = line.split(",").map((x) => parseInt(x));
    mapping.set({ i: lineItems[0], j: lineItems[1], k: lineItems[2] }, { x: lineItems[3], y: lineItems[4] });
  });
  return mapping;
};

export const fetchInjectionSitesData = async (basePath: string, templateType: TemplateType): Promise<InjectionSite[]> => {
  const lines = await fetchData(`${basePath}/LUT-SiteID_to_Site2D.csv`);
  const sites: InjectionSite[] = [];
  lines.forEach((line) => {
    const lineItems = line.split(",").map((x) => parseInt(x));
    if (lineItems.length === 4) {
      sites.push(
        new InjectionSite(
          templateType,
          lineItems[0] === 1 ? "XBTD22-J" : "XBTD22-N", lineItems[1], {
          x: lineItems[2],
          y: lineItems[3],
        })
      );
    }
  });
  return sites;
};

export const fetchCoronalActivityPointsData = async (pregneratedBasePath: string, sliceNumber: string): Promise<PointMap<Point2D, string[]>> => {
  const lines = await fetchData(`${pregneratedBasePath}/coronal_lookup/${sliceNumber}.csv`);
  const mapping = new PointMap<Point2D, string[]>();
  lines.forEach((line) => {
    const lineItems = line.split(",");
    if (lineItems.length > 3) {
      mapping.set({ x: parseInt(lineItems[0]), y: parseInt(lineItems[1]) }, lineItems.slice(2));
    }
  });
  return mapping;
};

export const getCoronalActivityPointsDataForSlice = async (pregneratedBasePath: string, sliceNumber: number): Promise<PointMap<Point2D, string[]>> => {
  // This is where we would cache the mapping table, if if turns out to be necessary
  const mapping = await fetchCoronalActivityPointsData(pregneratedBasePath, sliceNumber.toString().padStart(3, '0'));
  return mapping
}

export const getSiteParcellationImageName = (boundaryType: BoundaryType): string => {
  switch (boundaryType) {
    case "atlas":
      return "Site2D-Parcellation-CBCetal15.png";
    case "charm_lv1":
      return "Site2D-Parcellation-CHARM_lv1.png";
    case "charm_lv2":
      return "Site2D-Parcellation-CHARM_lv2.png";
    case "charm_lv3":
      return "Site2D-Parcellation-CHARM_lv3.png";
    case "charm_lv4":
      return "Site2D-Parcellation-CHARM_lv4.png";
    case "charm_lv5":
      return "Site2D-Parcellation-CHARM_lv5.png";
    case "charm_lv6":
      return "Site2D-Parcellation-CHARM_lv6.png";
    case "d99_12a":
    default:
      return "Site2D-Parcellation-D99_12a.png";
    }
};

export const getParcellationColorMapFromBoundary = (lookupTables: LookupTables, boundaryType: BoundaryType): ColorMap | undefined => {
  switch (boundaryType) {
    case "atlas":
      return lookupTables.parcellationColorMaps?.get('atlas_parcellation');
    case "charm_lv1":
      return lookupTables.parcellationColorMaps?.get('charm_lv1_parcellation');
    case "charm_lv2":
      return lookupTables.parcellationColorMaps?.get('charm_lv2_parcellation');
    case "charm_lv3":
      return lookupTables.parcellationColorMaps?.get('charm_lv3_parcellation');
    case "charm_lv4":
      return lookupTables.parcellationColorMaps?.get('charm_lv4_parcellation');
    case "charm_lv5":
      return lookupTables.parcellationColorMaps?.get('charm_lv5_parcellation');
    case "charm_lv6":
      return lookupTables.parcellationColorMaps?.get('charm_lv6_parcellation');
    case "d99_12a":
      default:
      return lookupTables.parcellationColorMaps?.get('d99_12a_parcellation');
    }
};


export const getConnectionParcellationImageName = (overlayType: OverlayType): string => {
  switch (overlayType) {
    case "atlas_parcellation":
      return "Conn2D-Parcellation-CBCetal15.View-Ipsi_Flat.png";
    case "charm_lv1_parcellation":
      return "Conn2D-Parcellation-CHARM_lv1.View-Ipsi_Flat.png";
    case "charm_lv2_parcellation":
      return "Conn2D-Parcellation-CHARM_lv2.View-Ipsi_Flat.png";
    case "charm_lv3_parcellation":
      return "Conn2D-Parcellation-CHARM_lv3.View-Ipsi_Flat.png";
    case "charm_lv4_parcellation":
      return "Conn2D-Parcellation-CHARM_lv4.View-Ipsi_Flat.png";
    case "charm_lv5_parcellation":
      return "Conn2D-Parcellation-CHARM_lv5.View-Ipsi_Flat.png";
    case "charm_lv6_parcellation":
      return "Conn2D-Parcellation-CHARM_lv6.View-Ipsi_Flat.png";
    case "d99_12a_parcellation":
    default:
      return "Conn2D-Parcellation-D99_12a.View-Ipsi_Flat.png";
    }
};

export const get3DParcellationImagePath = (overlayType: OverlayType): string => {
  switch (overlayType) {
    case "atlas_parcellation":
      return "3D-Parcellation-CBCetal15";
    case "charm_lv1_parcellation":
      return "3D-Parcellation-CHARM_SARM_lv1";
    case "charm_lv2_parcellation":
      return "3D-Parcellation-CHARM_SARM_lv2";
    case "charm_lv3_parcellation":
      return "3D-Parcellation-CHARM_SARM_lv3";
    case "charm_lv4_parcellation":
      return "3D-Parcellation-CHARM_SARM_lv4";
    case "charm_lv5_parcellation":
      return "3D-Parcellation-CHARM_SARM_lv5";
    case "charm_lv6_parcellation":
      return "3D-Parcellation-CHARM_SARM_lv6";
    case "d99_12a_parcellation":
    default:
      return "3D-Parcellation-D99_12a";
    }
};

export const get3DAtlasParcellationImagePath = () => "3D-Parcellation-CHARM_SARM_atlasID";

export const getParcellationColorMap = (lookupTables: LookupTables, overlayType: OverlayType, overlayVariant: OverlayVariant = 1): ColorMap | undefined => {
  switch (overlayType) {
    case "atlas_parcellation":
      return lookupTables.parcellationColorMaps?.get('atlas_parcellation');
    case "charm_lv1_parcellation":
      return lookupTables.parcellationColorMaps?.get(overlayVariant === 1 ? 'charm_lv1_parcellation' : 'sarm_lv1_parcellation');
    case "charm_lv2_parcellation":
      return lookupTables.parcellationColorMaps?.get(overlayVariant === 1 ? 'charm_lv2_parcellation' : 'sarm_lv2_parcellation');
    case "charm_lv3_parcellation":
      return lookupTables.parcellationColorMaps?.get(overlayVariant === 1 ? 'charm_lv3_parcellation' : 'sarm_lv3_parcellation');
    case "charm_lv4_parcellation":
      return lookupTables.parcellationColorMaps?.get(overlayVariant === 1 ? 'charm_lv4_parcellation' : 'sarm_lv4_parcellation');
    case "charm_lv5_parcellation":
      return lookupTables.parcellationColorMaps?.get(overlayVariant === 1 ? 'charm_lv5_parcellation' : 'sarm_lv5_parcellation');
    case "charm_lv6_parcellation":
      return lookupTables.parcellationColorMaps?.get(overlayVariant === 1 ? 'charm_lv6_parcellation' : 'sarm_lv6_parcellation');
    case "d99_12a_parcellation":
      default:
      return lookupTables.parcellationColorMaps?.get('d99_12a_parcellation');
    }
};

export const isCharmSarmParcellation = (overlayType: OverlayType): boolean => {
  return overlayType.startsWith("charm") || overlayType.startsWith("sarm")
};

export const getOverlayTypeFromBoundary = (boundary: BoundaryType): OverlayType => {
  return boundary + "_parcellation" as OverlayType;
}

// From https://www.bennadel.com/blog/1504-ask-ben-parsing-csv-strings-with-javascript-exec-regular-expression-command.htm
function CSVToArray(strData: string, strDelimiter: string): string[][] {
  // Check to see if the delimiter is defined. If not,
  // then default to comma.
  strDelimiter = strDelimiter || ",";

  // Create a regular expression to parse the CSV values.
  var objPattern = new RegExp(
    // Delimiters.
    "(\\" +
      strDelimiter +
      "|\\r?\\n|\\r|^)" +
      // Quoted fields.
      '(?:"([^"]*(?:""[^"]*)*)"|' +
      // Standard fields.
      '([^"\\' +
      strDelimiter +
      "\\r\\n]*))",
    "gi"
  );

  // Create an array to hold our data. Give the array
  // a default empty first row.
  var arrData: string[][] = [[]];

  // Create an array to hold our individual pattern
  // matching groups.
  var arrMatches = null;

  // Keep looping over the regular expression matches
  // until we can no longer find a match.
  // eslint-disable-next-line no-cond-assign
  while ((arrMatches = objPattern.exec(strData))) {
    // Get the delimiter that was found.
    var strMatchedDelimiter = arrMatches[1];

    // Check to see if the given delimiter has a length
    // (is not the start of string) and if it matches
    // field delimiter. If id does not, then we know
    // that this delimiter is a row delimiter.
    if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
      // Since we have reached a new row of data,
      // add an empty row to our data array.
      arrData.push([]);
    }

    var strMatchedValue;

    // Now that we have our delimiter out of the way,
    // let's check to see which kind of value we
    // captured (quoted or unquoted).
    if (arrMatches[2]) {
      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
    } else {
      // We found a non-quoted value.
      strMatchedValue = arrMatches[3];
    }

    // Now that we have our value string, let's add
    // it to the data array.
    arrData[arrData.length - 1].push(strMatchedValue);
  }

  // Return the parsed data.
  return arrData;
}
