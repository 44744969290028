import React from "react";
import { Point2D } from "../../types/Point2D";
import { createImage } from "../../utils/utils";
import Canvas from "../Canvas/Canvas";

export type InputImages = {
  width: number;
  height: number;
  urls: string[];
  combine: (contexts: CanvasRenderingContext2D[]) => HTMLCanvasElement | undefined;
};

type Props = {
  inputImages: InputImages;
  onSetClickedPoint?: (point: Point2D, shiftKey: boolean) => void;
  onSetMousePosition?: (point: Point2D) => void;
  onSetImageData?: (data: string) => void;
};

function CombinerCanvas({ inputImages, onSetClickedPoint, onSetMousePosition, onSetImageData }: Props) {
  const [imageData, setImageData] = React.useState<string>("");

  // Take an set of images and the function to be used to combine them. Return the combined image
  // as a data URL
  const generateImage = React.useCallback(async (inputImages: InputImages) => {
    const contexts = await Promise.all(
      inputImages.urls.map(async (url) => {
        const canvas = document.createElement("canvas");
        canvas.height = inputImages.height;
        canvas.width = inputImages.width;
        const context = canvas.getContext("2d");
        try {
          const image = await createImage(url);
          context?.drawImage(image, 0, 0, canvas.width, canvas.height);
        } catch (e) {
          // There are cases where we attempt to load an image without knowing if it actually
          // exists. For example, in cases where there is no connection activity, the connection
          // overlay activity files does not exist. In these cases, assume the image is empty
        }
        return context;
      })
    );

    if (!contexts) {
      console.error("Could not create contexts for input into applyBlending");
      return;
    }
    // Get an array that doesn't contain nulls.
    const filteredContexts: CanvasRenderingContext2D[] = contexts.filter(
      (x): x is CanvasRenderingContext2D => x !== null
    );
    // Call the closure provided by the calling component to use the specific logic required to
    // combine the images for that component
    const finalImage = inputImages.combine(filteredContexts);
    if (!finalImage) {
      console.error("Could not render final image");
      return;
    }
    return finalImage.toDataURL();
  }, []);

  // Everything that happens after the images are loaded needs to go in the useEffect hook
  React.useEffect(() => {
    const renderImage = async () => {
      const generatedImage = await generateImage(inputImages)
      setImageData((generatedImage) || "")
      if (generatedImage && onSetImageData) {
        onSetImageData(generatedImage);
      }
    };
    renderImage();
  }, [generateImage, inputImages, onSetImageData]);

  return (
    <>
      <Canvas
        width={inputImages.width}
        height={inputImages.height}
        imageUrl={imageData}
        onSetClickedPoint={onSetClickedPoint}
        onSetMousePosition={onSetMousePosition}
      />
    </>
  );
}

export default CombinerCanvas;
