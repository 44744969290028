import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import SitesNMT from "./pages/SitesNMT/SitesNMT";
import Publications from "./pages/Publications/Publications";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import Debug from "./pages/Debug/Debug";
import Generate from "./pages/Generate/Generate";
import SitesXBDTn from "./pages/SitesXBTDn/SitesXBTDn";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/nmt",
        element: <SitesNMT />,
      },
      {
        path: "/xbtdn",
        element: <SitesXBDTn />,
      },
      {
        path: "/publications",
        element: <Publications />,
      },
      {
        path: "/debug",
        element: <Debug />,
      },
      {
        path: "/generate",
        element: <Generate />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
