import React from "react";
import styles from "./StimulationMap.module.css";
import { Point2D } from "../../types/Point2D";
import { Point3D } from "../../types/Point3D";
import { SlicePosition } from "../../types/SlicePosition";
import { InjectionSite } from "../../types/InjectionSite";
import InjectionSiteMarker from "../InjectionSiteMarker/InjectionSiteMarker";
import SiteBoundaryCanvas from "../SiteBoundaryCanvas/SiteBoundaryCanvas";
import Marker from "../Marker/Marker";
import LookupTablesContext from "../../contexts/LookupTablesContext";
import SiteRegionLabel from "../SiteRegionLabel/SiteRegionLabel";
import { BoundaryType } from "../../types/OverlayParameters";
import arrows from "../../assets/images/arrows.png";
import scale from "../../assets/images/scale.png";

type Props = {
  slicePosition: SlicePosition | null;
  activeSites: string[] | null;
  boundary: BoundaryType;
  onSetSlicePosition: (position: SlicePosition) => void;
  onSetInjectionSites: (sites: InjectionSite[]) => void;
  onSetSelectedPoint: (point: Point2D) => void;
};

function StimulationMap({
  slicePosition,
  activeSites,
  boundary,
  onSetSlicePosition,
  onSetInjectionSites,
  onSetSelectedPoint,
}: Props) {
  const [selectedInjectionSites, setSelectedInjectionSites] = React.useState<InjectionSite[]>([]);
  const [mouseOverActiveLocation, setMouseOverActiveLocation] = React.useState<Boolean>();
  const [mouseLocationPoint, setMouseLocationPoint] = React.useState<Point2D>();
  const [displayPoint, setDisplayPoint] = React.useState<Point2D>();
  const [oldSlicePosition, setOldSlicePosition] = React.useState<SlicePosition>();
  const lookupTables = React.useContext(LookupTablesContext);

  // If the slice position has changed on us, reset the marker point
  if (
    oldSlicePosition &&
    slicePosition &&
    (oldSlicePosition.axial !== slicePosition.axial ||
      oldSlicePosition.coronal !== slicePosition.coronal ||
      oldSlicePosition.sagittal !== slicePosition.sagittal)
  ) {
    setOldSlicePosition(slicePosition);
    setDisplayPoint(lookupTables.stimulationSites3Dto2DMap?.get(new Point3D(slicePosition)));
  }

  // When the user clicks on the image, place the marker and update the selected 3d location
  const handleClick = (point: Point2D) => {
    const lookupResult = lookupTables.stimulationSites2Dto3DMap?.get(point);
    if (lookupResult) {
      setDisplayPoint(point);
      onSetSelectedPoint(point);
      setOldSlicePosition(new SlicePosition(lookupResult));
      onSetSlicePosition(new SlicePosition(lookupResult));
    }
  };

  // Update the mouse cursor based on whether or not they are a over a location that maps
  // to a valid point in the 3d image
  const handleMouseMove = (point: Point2D) => {
    if (point) {
      setMouseOverActiveLocation(lookupTables.stimulationSites2Dto3DMap?.get(point) !== undefined);
      setMouseLocationPoint(point);
    }
  };

  // Handle clicking on an injection site
  // - if no modifier key held down, switch to the new site only
  // - if shift key held down and site is NOT already selected, add to the list of selected sites
  // - if shift key held down and site IS already selected, remove it from the list of selected sites
  const handleSetInjectionSite = (site: InjectionSite, multiSelect: boolean) => {
    let newInjectionSites: InjectionSite[];
    if (multiSelect) {
      newInjectionSites = [...selectedInjectionSites];
      if (newInjectionSites.includes(site)) {
        newInjectionSites = newInjectionSites.filter((s) => s !== site);
      } else {
        newInjectionSites.push(site);
      }
    } else {
      newInjectionSites = [site];
    }

    // Keep the selected injection site in our local storage
    setSelectedInjectionSites(newInjectionSites);
    // Notify the app that the injection site has changed
    onSetInjectionSites(newInjectionSites);
    // Set the selected point
    handleClick(site.location);
  };

  // Display clickable injection site markers
  const displayInjectionSites = (sites: InjectionSite[] | undefined) => {
    if (!sites) {
      return [];
    }

    return sites.map((s) => (
      <InjectionSiteMarker
        injectionSite={s}
        key={s.getShortName()}
        onSetInjectionSite={handleSetInjectionSite}
        isSelected={selectedInjectionSites.includes(s)}
        isBackgrounded={
          selectedInjectionSites.length > 0 &&
          activeSites != null &&
          !selectedInjectionSites.includes(s) &&
          !activeSites.includes(s.getShortName())
        }
      />
    ));
  };

  // Set default position for selection point on initial load
  React.useEffect(() => {
    handleClick({ x: 230, y: 295 });
  }, []); // eslint-disable-line

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.imageWrapper}
        style={{
          cursor: mouseOverActiveLocation ? "crosshair" : "not-allowed",
          backgroundImage: `url(${lookupTables.pathImagesSite2d}/Site2D-Background.png)`,
        }}
      >
        <img
          className={styles.borderImage}
          src={`${lookupTables.pathImagesSite2d}/Site2D-Border.png`}
          style={{
            left: lookupTables.siteBorderLeftOffset,
            top: lookupTables.siteBorderTopOffset,
          }}
          alt=""
        />
        <img src={arrows} className={styles.arrows} alt="" />
        <img src={scale} className={styles.scale} alt="" />
        <SiteBoundaryCanvas boundary={boundary} onSetClickedPoint={handleClick} onSetMousePosition={handleMouseMove} />
        {slicePosition && <Marker point={displayPoint} />}
        {displayInjectionSites(lookupTables.injectionSites || [])}
      </div>
      <div className={styles.infoWrapper}>
        {mouseLocationPoint && <SiteRegionLabel point={mouseLocationPoint} boundary={boundary} />}
        <div className={styles.injectionSiteWrapper}>
          {selectedInjectionSites.length > 0 && (
            <div
              className={`${styles.injectionSiteCaption} ${styles[selectedInjectionSites[0].subject.toLowerCase()]}`}
            >
              <label>Selected site(s) </label>
              <span>{selectedInjectionSites.map((s) => s.getShortName()).join(", ")}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default StimulationMap;
