import React from "react";
import { Helmet } from "react-helmet-async";
import Connections from "../../components/Connections/Connections";
import ConnectionsHelp from "../../components/ConnectionsHelp/ConnectionsHelp";
import StimulationSites from "../../components/StimulationSites/StimulationSites";
import AppNameContext from "../../contexts/AppNameContext";
import LookupTablesContext, { LookupTables } from "../../contexts/LookupTablesContext";
import { InjectionSite } from "../../types/InjectionSite";
import { SliceType } from "../../types/SliceType";
import { initializeLookupData } from "../../utils/lookup";
import styles from "./SitesXBTDn.module.css";

function SitesXBTDn() {
  const [injectionSites, setInjectionSites] = React.useState<InjectionSite[]>([]);
  const [activeSites, setActiveSites] = React.useState<string[] | null>(null);
  const [lookupTables, setLookupTables] = React.useState<LookupTables>(new LookupTables());

  React.useEffect(() => {
    initializeLookupData(setLookupTables, "Template-XBTD22n", ["atlas_parcellation", "d99_12a_parcellation"]);
    setLookupTables((previousTable) => {
      return {
        ...previousTable,
        availableStimulationSitesBoundaries: ["atlas", "sulcigyri", "d99_12a"],
        templateName: "XBTD22",
        ktoRTransform: (k: number) => {
          return -36.1562 + 0.5 * k;
        },
        itoATransform: (i: number) => {
          return 55.9775 - 0.5 * i;
        },
        jtoSTransform: (j: number) => {
          return 117.2722 - 0.5 * j;
        },
        rtoKTransform: (r: number) => {
          return Math.round(2 * (r + 36.1562));
        },
        atoITransform: (a: number) => {
          return Math.round(2 * (55.9775 - a));
        },
        stoJTransform: (s: number) => {
          return Math.round(2 * (117.2722 - s));
        },
        siteBorderLeftOffset: -35,
        siteBorderTopOffset: -40,
        connectionsBorderLeftOffset: -29,
        connectionsBorderTopOffset: -32,
        sliceCountMap: new Map<SliceType, number>([
          ["axial", 118],
          ["coronal", 170],
          ["sagittal", 134],
        ]),
        imageDimensions3D: new Map<SliceType, { width: number; height: number }>([
          ["axial", { width: 134, height: 170 }],
          ["coronal", { width: 134, height: 118 }],
          ["sagittal", { width: 170, height: 118 }],
        ]),
      };
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>{React.useContext(AppNameContext)} - Stimulation Sites</title>
      </Helmet>
      <div className={styles.wrapper}>
        <LookupTablesContext.Provider value={lookupTables}>
          {lookupTables.connections2Dto3DMap &&
            lookupTables.parcellationColorMaps &&
            lookupTables.connections2Dto3DMap &&
            lookupTables.connections3Dto2DMap &&
            lookupTables.stimulationSites2Dto3DMap &&
            lookupTables.stimulationSites3Dto2DMap &&
            lookupTables.injectionSites && (
              <>
                <div>
                  <StimulationSites onSetInjectionSites={setInjectionSites} activeSites={activeSites} />
                </div>
                <div>
                  {!injectionSites.length && <ConnectionsHelp />}
                  {injectionSites.length > 0 && (
                    <Connections injectionSites={injectionSites} onSetActiveSites={setActiveSites} />
                  )}
                </div>
              </>
            )}
        </LookupTablesContext.Provider>
      </div>
    </div>
  );
}

export default SitesXBTDn;
