import React from "react";
import { useSearchParams } from "react-router-dom";
import GenerateImage from "../../components/GenerateImage/GenerateImage";
import LookupTablesContext, { LookupTables } from "../../contexts/LookupTablesContext";
import { InjectionSite } from "../../types/InjectionSite";
import { TemplateType } from "../../types/TemplateType";
import { initializeLookupData } from "../../utils/lookup";
import styles from "./Generate.module.css";

function Generate() {
  const [lookupTables, setLookupTables] = React.useState<LookupTables>(new LookupTables());
  const [activityLookupTable, setActivityLookupTable] = React.useState<Map<string, number[]>>(
    new Map<string, number[]>()
  );
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();


  const handleSetActivePixels = (injectionSite: InjectionSite) => (activePixels: number[]) => {
    setActivityLookupTable((currentActivityLookupTable) => {
      return new Map<string, number[]>(currentActivityLookupTable).set(injectionSite.getShortName(), activePixels);
    });
  };

  // Display clickable injection site markers
  const displayConnectionImages = (sites: InjectionSite[] | undefined) => {
    if (!sites) {
      return [];
    }
    return sites.map((s) => (
      <GenerateImage injectionSite={s} onSetActivePixels={handleSetActivePixels(s)} key={s.getShortName()} />
    ));
  };

  const transformLookupTable = (data: Map<string, number[]>) => {
    const exportMap: Map<number, string[]> = new Map<number, string[]>();
    data.forEach((positions: number[], injectionPointName: string) => {
      positions.forEach((position) => {
        if (exportMap.has(position)) {
          exportMap.set(position, [...(exportMap.get(position) ?? ""), injectionPointName]);
        } else {
          exportMap.set(position, [injectionPointName]);
        }
      });
    });
    let result = "";
    exportMap.forEach((names: string[], position: number) => {
      const y = Math.floor(position / 4 / 500);
      const x = (position / 4) % 500;
      result += [x, y, ...names].join(",") + "\n";
    });
    return result;
  };

  React.useEffect(() => {
    const templateFromUrl = searchParams.get("template");
    const fragment: TemplateType = templateFromUrl ? templateFromUrl as TemplateType : "Template-XBTD22n";
    initializeLookupData(setLookupTables, fragment, []);
  }, []);


  return (
    <LookupTablesContext.Provider value={lookupTables}>
      {lookupTables.connections2Dto3DMap &&
        lookupTables.parcellationColorMaps &&
        lookupTables.connections2Dto3DMap &&
        lookupTables.connections3Dto2DMap &&
        lookupTables.stimulationSites2Dto3DMap &&
        lookupTables.stimulationSites3Dto2DMap &&
        lookupTables.injectionSites && (
          <div>
            <textarea value={transformLookupTable(activityLookupTable)} readOnly />
            <div className={styles.wrapper}>{displayConnectionImages(lookupTables.injectionSites || [])}</div>
          </div>
        )}
    </LookupTablesContext.Provider>
  );
}

export default Generate;
