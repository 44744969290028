import React from "react";
import LookupTablesContext from "../../contexts/LookupTablesContext";
import { OverlayType } from "../../types/OverlayParameters";
import { get3DAtlasParcellationImagePath, get3DParcellationImagePath, getParcellationColorMap } from "../../utils/lookup";
import { SlicePosition } from "../../types/SlicePosition";
import { createImage, isHighPixelValue } from "../../utils/utils";
import { isCharmSarmParcellation } from "../../utils/lookup";
import styles from "../RegionLabel/RegionLabel.module.css";

type Props = {
  slicePosition: SlicePosition;
  overlayType: OverlayType;
};

function RegionLabel3D({ slicePosition, overlayType }: Props) {
  const lookupTables = React.useContext(LookupTablesContext);
  const [parcellationImageData, setParcellationImageData] = React.useState<ImageData>();
  const [atlasImageData, setAtlasImageData] = React.useState<ImageData>();
  const [lastParcellationOverlayType, setLastParcellationOverlayType] = React.useState<OverlayType>();

  const IMAGE_WIDTH = lookupTables.imageDimensions3D.get('sagittal')?.width ?? 0;
  const IMAGE_HEIGHT = lookupTables.imageDimensions3D.get('sagittal')?.height ?? 0;

  // We will use the Saggital slice to get the region label  
  React.useEffect(() => {

    const getImageDataForImagePath = async (imagePath: string) => {
      const canvas = document.createElement("canvas");
      canvas.height = IMAGE_HEIGHT;
      canvas.width = IMAGE_WIDTH;
      const context = canvas.getContext("2d");
      const image = await createImage(imagePath);
      context?.drawImage(image, 0, 0, canvas.width, canvas.height);
      return context?.getImageData(0, 0, canvas.width, canvas.height);
    }


    const renderImages = async () => {
      if (overlayType !== 'connections') {
        setLastParcellationOverlayType(overlayType);
      }
      const overlayTypeToUse = lastParcellationOverlayType || overlayType;
      const parcellationImagePath = get3DParcellationImagePath(overlayTypeToUse);
      const paddedIndex = String(slicePosition.sagittal).padStart(3, "0");
      const typeBase = `View-3_Sagittal.InView_XY-3D_JI/3D_K-${paddedIndex}.png`;

      const parcellationImageData = await getImageDataForImagePath(`${lookupTables.pathImagesSite3d}/${parcellationImagePath}/${typeBase}`)
      setParcellationImageData(parcellationImageData);

      if (isCharmSarmParcellation(overlayTypeToUse)) {
        const atlasImagePath = get3DAtlasParcellationImagePath();
        const atlasImageData = await getImageDataForImagePath(`${lookupTables.pathImagesSite3d}/${atlasImagePath}/${typeBase}`)
        setAtlasImageData(atlasImageData);
      }
    }

    renderImages();
  }, [slicePosition, overlayType]);

  // const colorMap = getParcellationColorMap(lookupTables, overlayType);
  // const imagePath = getConnectionParcellationImageName(overlayType);

  const lookupLabels = (slicePosition: SlicePosition, parcellationImageData: ImageData | undefined, atlasImageData: ImageData | undefined) => {
    const overlayTypeToUse = lastParcellationOverlayType || overlayType;
    const point = { x: slicePosition.coronal, y: slicePosition.axial };
    if (point &&  parcellationImageData) {
      const colorIndex = (point.y - 1) * (IMAGE_WIDTH * 4) + (point.x - 1) * 4;      
      if (colorIndex < parcellationImageData.data.length) {
        let colorMap = getParcellationColorMap(lookupTables, overlayTypeToUse);
        // If ChARM/SARM, we need to lookup the atlas image to determine which color map to use
        if (isCharmSarmParcellation(overlayTypeToUse)) {
          const atlasMappingIndex = atlasImageData?.data[colorIndex];
          if (atlasMappingIndex && isHighPixelValue(atlasMappingIndex)) {
            colorMap = getParcellationColorMap(lookupTables, overlayTypeToUse, 2);
          }
        }

        const result = colorMap?.get(parcellationImageData.data[colorIndex]);
        return result;
      }
    }
  };
  const label = lookupLabels(slicePosition, parcellationImageData, atlasImageData);
  return <div className={styles.wrapper}>{label && `${label?.label}`}{label?.longLabel && `: ${label?.longLabel}`}&nbsp;</div>;
}

export default RegionLabel3D;
