import { BASE_PATH_DATA, BASE_PATH_PREGENERATED } from "../constants/constants";
import { Point2D } from "./Point2D";
import { SubjectType } from "./SubjectType";
import { TemplateType } from "./TemplateType";

export class InjectionSite {
  siteNumber: number;
  location: Point2D;
  subject: SubjectType;
  templateType: TemplateType;

  private getSubjectCode(): string {
    return this.subject.slice(-1);
  }

  private getPaddedIndex(): string {
    return String(this.siteNumber).padStart(3, "0");
  }

  public getImage(): string {
    return `${BASE_PATH_DATA}/${this.templateType}/Images-Conn2D/Conn2D-Connection/SiteID-${this.getSubjectCode()}_${this.getPaddedIndex()}.View-Ipsi_Flat.png`;
  }

  public getColoredImage(): string {
    return `${BASE_PATH_PREGENERATED}/${this.templateType}/${this.getShortName()}.png`;
  }

  public getShortName(): string {
    return this.getSubjectCode() + "_" + this.getPaddedIndex();
  }

  constructor(templateType: TemplateType, subject: SubjectType, siteNumber: number, location: Point2D) {
    this.templateType = templateType;
    this.subject = subject;
    this.siteNumber = siteNumber;
    this.location = location;
  }
}
