import React from 'react'
import styles from "./ColorBar.module.css";
import colorbar from '../../assets/images/colorbar.png'


function ColorBar() {
  return (
    <div className={styles.wrapper}>
        <div className={styles.barWrapper}><span>0</span> <img src={colorbar} alt="Color gradient used in connection display" /> <span>0.8</span></div>
        <div>log.norm. CNR</div>
    </div>
  )
}

export default ColorBar