import React from "react";
import { Helmet } from "react-helmet-async";
import AppNameContext from "../../contexts/AppNameContext";
import styles from "./About.module.css";
import background from "../../assets/images/background-about.svg";

function About() {

    return (
        <div>
            <Helmet><title>{React.useContext(AppNameContext)} - About</title></Helmet>
            <div className={styles.pageWrapper} style={{backgroundImage: 'url(' + background + ')'}}>
                <div className={styles.wrapper}>
                    <h1>About the dense connectome website</h1>
                    <p>                   
                    To inform the organization of primate lateral prefrontal cortex (LPFC), we performed simultaneous electrical microstimulation and functional magnetic resonance imaging (EM-fMRI) to map <em>in vivo</em> connectivity of ~200 LPFC sites in two rhesus macaques (<em>Macaca mulatta</em>). The EM-fMRI connectome is “dense”, because its resolution is finer than typical cytoarchitectonic areas, which many anatomical connectivity studies rely on. In each subject, we sampled the LPFC at the average spatial interval of ~2 millimeters, and we set the voxel size of fMRI to 2 millimeters isotropic. The interareal LPFC-cortical connectivity matrix derived from the (downsampled) EM-fMRI connectome seems consistent with monosynaptic tract tracing (Xu et al., 2022).
                    </p>
                    <p>
                    We found the LPFC contains topographic maps of other major processing domains of ipsilateral association cortex, including temporal, posterior parietal, orbitofrontal and insular, medial prefrontal, and posteromedial cortices (Xu et al., 2022). The 2D spatial layout of stimulation sites in the LPFC preserves, to some extent, the layout of their connection zones in the domains LPFC connects to. This architecture may allow different LPFC regions to integrate/bias neural processing at the appropriate level of each domain, which usually has an orderly spatial organization of its own. We now share the ipsilateral LPFC-cortical connectivity data in this website, with UIs designed to help browse through 2D/3D brain coordinates.
                    </p>
                    <p>
                    Stimulation sites and EM-fMRI activations (or “connections”) are presented in the coordinates of one of our subjects (XBTD22n) as well as NIMH Macaque Template v2.0 (NMT20sym). For XBTD22n, D99 v1.2a (D99_12a) and CBCetal15 (based on the Paxinos atlas and used in our 2022 paper; <a href="https://scalablebrainatlas.incf.org/macaque/CBCetal15">link</a>) parcellations are included. For NMT20sym, D99 v1.2a and CHARM/SARM (provided with NMT v2.0; 6 levels each) parcellations are included.
                    </p>
                    <p>
                    Currently the subcortical and contralateral cortical connections are masked out, and we will upload unmasked data as soon as we can. The data hosted here are in the format of processed brain images (log-normalized contrast-to-noise ratio, or “log-norm. CNR”), which are intended to be used as a connectional atlas, and are not meant to be used in quantitative analysis (e.g., based on image intensity, which can be inaccurate). We will release the data matrix (processed and raw) separately, and before then you are welcome to directly contact Rui Xu (<a href="mailto:ruix@mit.edu">ruix@mit.edu</a>) about data sharing, for which co-authorship is <em>not</em> a prerequisite.                        
                    </p>
                    <p>
                    Any questions/suggestions can be addressed to <a href="mailto:ruix@mit.edu">ruix@mit.edu</a>.
                    </p>
                    <h2>License</h2> 
                    <p>            
                    The images and other materials we shared in this website are licensed under a Creative Commons Attribution-ShareAlike 4.0 (CC-BY-SA) License (<a href="https://creativecommons.org/licenses/by-sa/4.0/">link</a>). You are free to share (copy and redistribute) and adapt (remix, transform, and build upon) the materials in any medium or format as long as you attribute the original source and provide link to URLs of this website and the license. If you adapt the materials, you must distribute your contributions under the same license as the original.
                    </p>
                    <h2>How to cite</h2>
                    <p>
                    Xu, R., Bichot, N.P., Takahashi, A., and Desimone, R. (2022). The cortical connectome of primate lateral prefrontal cortex. Neuron <em>110</em>, 312-327.e7. DOI: 10.1016/j.neuron.2021.10.018.
                    </p>
                    <h2>Contributors</h2>
                    <p>
                    The study to map the dense connectome of macaque LPFC was conducted by a team of researchers at the Desimone Lab and McGovern Institute for Brain Research at MIT, including Rui Xu, Narcisse P. Bichot, Atsushi Takahashi, and Robert Desimone. This website is created in partnership by Opus Design, Pod Consulting and Rui Xu.
                    </p>
                    <p>
                    We thank P.K. Weigand, A.L. Marino, and E.M. DeGennaro for technical assistance; J. Estrada for co-designing and making implants; R.P. Marini and the MIT Division of Comparative Medicine staff for veterinary support; S. Shannon for assistance in MRI; and C.W.-H. Wu, R. Rajimehr, S. Kornblith, and J. Donoghue for feedback. This work was supported by NEI R01-EY029666 to R. Desimone.
                    </p>
                    <h2>Additional notes</h2>
                    <p>
                    FreeSurfer-format surface files of NMT20sym are generated using customized scripts (based on Sebastian Moeller&apos;s; <a href="https://surfer.nmr.mgh.harvard.edu/fswiki/MonkeyData">link</a>). We also downsampled the template brain of NMT20sym to 0.5-mm isotropic resolution (cubic interpolation) for faster visualization.
                    </p>
                    <p>
                    I/J/K coordinates are 1-index (in contrast, FreeSurfer uses 0-index), and we always show closest I/J/K coordinates with integer values. R/A/S coordinates are in mm, and are the same as the “RAS” (not “TkReg RAS”) coordinates found in FreeView (the image browser of FreeSurfer). See this <a href="https://surfer.nmr.mgh.harvard.edu/fswiki/CoordinateSystems">link</a> for more details on the coordinate systems of FreeSurfer.                        
                    </p>
                    <p>
                    For CHARM and SARM parcellations, “n1/n2” after the area name indicates the lowest (coarse) and highest (fine) levels of parcellation that include the area.                        
                    </p>
                    <p>
                    Cortical parcellation of D99 should match CHARM_lv6 exactly, and a lower-level area in CHARM should cover its corresponding higher-level subareas exactly. However, be aware this is not ensured by the algorithm we used to convert parcellations from volume to surface. Rely on volumetric parcellations (in 3D views) when needed.                        
                    </p>
                    <p>
                    “MeanCurv” calculated in ANTs is used in the visualization of gyri/sulci for both XBTD22n and NMT20sym.                        
                    </p>
                </div>
            </div>        
        </div>  
    );
  }
  
  export default About;
  