import React from 'react'
import styles from "./DeviceWarning.module.css";
import monitor from "../../assets/images/monitor.svg"

function DeviceWarning() {
  return (
    <div className={styles.wrapper}>
      <img src={monitor} alt=''/>
      <p>For the best experience, please view on a desktop computer</p>
    </div>
  )
}

export default DeviceWarning