import React from "react";
import { Helmet } from "react-helmet-async";
import AppNameContext from "../../contexts/AppNameContext";
function Publications() {
    return (
        <div>
            <Helmet><title>{React.useContext(AppNameContext)} - Publications</title></Helmet>
            Publications
        </div>
    );
  }
  
  export default Publications;
  